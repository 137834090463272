import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PRODUCT, DEFAULT_SCENE } from '../../components/WebGL/WebGLApp/utils/constants';

const initialState = {
  percentLoaded: 0,
  loadingComplete: false,
  scene: DEFAULT_SCENE,
  product: DEFAULT_PRODUCT,
  showHotspot: false,
  hotspot: {
    id: null,
    x: null,
    y: null
  },
  webGLReady: false
};

export const webGLSlice = createSlice({
  name: 'webGL',
  initialState: initialState,
  reducers: {
    setPercentLoaded(state, action) {
      return { ...state, percentLoaded: action.payload };
    },
    setLoadingComplete(state, action) {
      return { ...state, loadingComplete: action.payload };
    },
    setWebGLScene(state, action) {
      return { ...state, scene: action.payload };
    },
    setProduct(state, action) {
      return { ...state, product: action.payload };
    },
    setHotspot(state, action) {
      return { ...state, hotspot: action.payload };
    },
    setShowHotspot(state, action) {
      return { ...state, showHotspot: action.payload };
    },
    setWebGLReady(state, action) {
      return { ...state, webGLReady: action.payload };
    },
    resetHotspot(state) {
      return {
        ...state,
        hotspot: {
          id: null,
          x: null,
          y: null
        }
      };
    }
  }
});
