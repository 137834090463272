import React, { memo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkProps from '@jam3/react-check-extra-props';

import HoverArea from '../HoverArea/HoverArea';

import useCopy from '../../utils/hooks/use-copy';

import { cursorKeys } from '../../keys/cursor';

import { getSeenCookiesModal, setSeenCookiesModal } from '../../utils/localStorage';

import styles from './CookiesModal.module.scss';

function CookiesModal({ className }) {
  const [acceptedCookies, setAcceptedCookies] = useState(getSeenCookiesModal());
  const { cookies: COPY } = useCopy();

  const handleClick = () => {
    if (!getSeenCookiesModal()) {
      setAcceptedCookies(true);
      setSeenCookiesModal(true);
    }
  };

  return (
    <div className={classnames(styles.CookiesModal, className, { [styles.hide]: acceptedCookies })}>
      <p className={styles.title}>{COPY.title}</p>
      <p className={styles.description}>
        {COPY.description}
        <HoverArea cursorType={cursorKeys.FOCUS} className={styles.hoverArea}>
          <a href={COPY.learnMore.href} target="_blank" rel="noreferrer">
            {COPY.learnMore.label}
          </a>
        </HoverArea>
      </p>
      <HoverArea cursorType={cursorKeys.FOCUS} className={styles.buttonHoverArea}>
        <button className={styles.button} onClick={handleClick}>
          {COPY.buttonLabel}
        </button>
      </HoverArea>
      <HoverArea cursorType={cursorKeys.FOCUS} className={styles.settingsHoverArea}>
        <a className={styles.settings} href={COPY.settings.href} target="_blank" rel="noreferrer">
          {COPY.settings.label}
        </a>
      </HoverArea>
    </div>
  );
}

CookiesModal.propTypes = checkProps({
  className: PropTypes.string
});

CookiesModal.defaultProps = {};

export default memo(CookiesModal);
