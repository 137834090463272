import blackImage from '../assets/images/black-bravo-in-space-large.jpg';
import whiteThumbnail from '../assets/images/white-bravo-in-space-cta.jpg';
import explodedRender from '../assets/images/exploded-render.jpg';

export default {
  title: 'Beam',
  description:
    'This new generation of Beam features an updated grille design, a faster processor for heightened depth, clarity, and spaciousness, and an HDMI eARC connection that supports higher resolution audio formats, including Dolby Atmos.',
  mediaKitDescription:
    'Effortlessly enrich your entertainment experience with panoramic sound for TV, music, and more.',
  discoverLabel: 'Click To Discover',
  rotateLabel: 'Drag To Rotate',
  mediaLabel: 'View Media Kit',
  priceLabel: 'Price',
  currencies: ['USD', 'GBP', 'EUR', 'AUD'],
  prices: {
    USD: '$449 USD',
    GBP: '£449 GBP',
    EUR: '€499 EUR',
    AUD: '$699 AUD'
  },
  thumbnailImage: {
    src: explodedRender,
    alt: 'Exploded render thumbnail image'
  },
  images: {
    white: {
      src: blackImage,
      alt: 'SONOS White Bravo in Space',
      thumbnailImage: {
        src: whiteThumbnail,
        alt: 'SONOS White Bravo in Space Thumbnail'
      }
    },
    black: {
      src: blackImage,
      alt: 'SONOS Black Bravo in Space',
      thumbnailImage: {
        src: whiteThumbnail,
        alt: 'SONOS Black Bravo in Space Thumbnail'
      }
    }
  },
  explode: {
    title: 'Beam',
    description:
      'Five powerful internal speakers fill your space from wall to wall with crystal clear dialogue and vibrant bass plus the precise and immersive experience of Dolby Atmos.',
    viewCTA: 'View Full Details',
    close: 'Close',
    hotspots: [
      {
        title: '40% faster CPU',
        description:
          'A 40% faster chip offers advanced audio processing power for an astonishingly spacious soundstage.',
        bottomAligned: true
      },
      {
        title: 'Amplifiers',
        description:
          'All five Class-D digital amplifiers have been perfectly tuned to the unique acoustic architecture.',
        bottomAligned: true
      },
      {
        title: 'Phased Speaker Arrays',
        description:
          'The faster CPU enables two new audio paths that include surround and height channels to deliver all home theater experiences with greater depth and clarity with the same acoustic design and compact footprint.',
        bottomAligned: true
      },
      {
        title: 'Passive Radiator',
        description: 'Three passive radiators enhance low frequencies for balanced, powerful sound.'
      },
      {
        title: 'Tweeter',
        description: 'One center tweeter creates a crisp high-frequency response.'
      },
      {
        title: 'Mid-Woofer',
        description:
          'Four elliptical midwoofers ensure faithful playback of mid-range frequencies and maximize low-end output.'
      }
    ]
  }
};
