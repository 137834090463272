import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPlaying: true,
  isAutoPlay: true,
  currentVideoProgress: 0,
  isPersonaHoldPlaying: false
};

export const videoSlice = createSlice({
  name: 'video',
  initialState: initialState,
  reducers: {
    setIsPlaying(state, action) {
      return { ...state, isPlaying: action.payload || !state.isPlaying };
    },
    setPlaybackProgress(state, action) {
      return { ...state, currentVideoProgress: action.payload };
    },
    setIsPersonaHoldPlaying(state, action) {
      return { ...state, isPersonaHoldPlaying: action.payload };
    }
  }
});
