const fetcher = (url, data = {}) => {
  const options = {
    ...data
  };

  options.headers = { ...data.headers };

  try {
    return fetch(url, options);
  } catch (err) {
    console.error(`Failed to fetch: ${err}`); // TypeError: failed to fetch
  }
};
export default fetcher;
