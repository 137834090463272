import { PlaneGeometry, Mesh, Group, Vector4, Vector2 } from 'three';
import WaveMaterial from './wave-material';

export default class WaveElements {
  constructor(props) {
    this.props = props;

    const { config, colorSet } = props;
    this.config = config;

    this.mesh = new Group();
    this.mousePosition = new Vector4();
    this.mouseDirection = new Vector2();
    this.materials = [];
    this.autoUpdateAnimation = true;

    this.currentColor = new Vector4();
    this.currentLightColor = new Vector4();
    this.opacity = { value: 0.0 };
    this.amount = { value: 0.0 };

    this.createGeometry(colorSet);
    this.updateMaterialUniforms();
  }

  createGeometry = (colorSet) => {
    let lines = this.config.lines;
    let positionY = this.config.startY;
    let lineWidth = this.config.lineWidth;
    let vpu = this.config.vpu;
    let step = this.config.spaceVariation;

    const { elementColor, lightColor } = colorSet;

    for (let i = 0; i < lines; i++) {
      const material = WaveMaterial({
        color: elementColor,
        lightColor,
        offset: new Vector4(
          this.config.offset.x * i,
          this.config.offset.y * i,
          0.1 + i * this.config.offset.z,
          (i + 1) * this.config.offset.w
        )
      });
      const geometry = new PlaneGeometry(lineWidth, this.config.thickness, Math.ceil(lineWidth * vpu), 1);
      const mesh = new Mesh(geometry, material);
      mesh.position.set(0, positionY, 0.1 * i);
      this.materials.push(material);
      this.mesh.add(mesh);
      positionY += step;
    }

    return this.mesh;
  };

  getOpacity = () => {
    return this.opacity;
  };

  getAmount = () => {
    return this.amount;
  };

  updateOpacity = (opacity) => {
    if (this.materials.length > 0) {
      for (let i = 0; i < this.materials.length; i++) {
        const material = this.materials[i];
        material.uniforms.uOpacity.value = opacity;
      }

      this.opacity.value = opacity;
    }
  };

  updateAmount = (amount) => {
    if (this.materials.length > 0) {
      for (let i = 0; i < this.materials.length; i++) {
        const material = this.materials[i];
        material.uniforms.uAmount.value = amount;
      }

      this.amount.value = amount;
    }
  };

  getMaterialColor = () => {
    return this.currentColor;
  };

  getMaterialLightColor = () => {
    return this.currentLightColor;
  };

  updateMaterialTime = (time) => {
    if (!this.autoUpdateAnimation && this.material) {
      this.material.uniforms.uTime.value = time;
    }
  };

  updateMaterialColor = (color) => {
    if (this.materials.length > 0) {
      for (let i = 0; i < this.materials.length; i++) {
        const material = this.materials[i];
        material.uniforms.uColor.value = color;
      }

      this.currentColor = color;
    }
  };

  updateMaterialLightColor = (color) => {
    if (this.materials.length > 0) {
      for (let i = 0; i < this.materials.length; i++) {
        const material = this.materials[i];
        material.uniforms.uLightColor.value = color;
      }

      this.currentLightColor = color;
    }
  };

  updateMaterialUniforms = () => {
    if (this.materials.length > 0) {
      for (let i = 0; i < this.materials.length; i++) {
        const material = this.materials[i];
        material.uniforms.uLightIntensity.value = this.config.lightIntensity;
        material.uniforms.uLightRadius.value = this.config.lightRadius;
        material.uniforms.uLightSpread.value = this.config.lightSpread;

        material.uniforms.uDistortionIntensity.value.x = this.config.waveIntensityX;
        material.uniforms.uDistortionIntensity.value.y = this.config.waveIntensityY;
        material.uniforms.uDistortionIntensity.value.z = this.config.waveSpeedIn;
        material.uniforms.uDistortionIntensity.value.w = this.config.waveSpeedOut;

        material.uniforms.uNoiseIntensity.value.x = this.config.noiseSpeed;
        material.uniforms.uNoiseIntensity.value.y = this.config.noiseTile;
        material.uniforms.uNoiseIntensity.value.z = this.config.noiseOffsetY;
        material.uniforms.uNoiseIntensity.value.w = this.config.noiseIntensity;

        material.uniforms.uDistortionAmount.value.x = this.config.distortionRadius;
        material.uniforms.uDistortionAmount.value.y = this.config.distortionSpread;
        material.uniforms.uDistortionAmount.value.z = this.config.distortionPower;
        material.uniforms.uDistortionAmount.value.w = this.config.distortionIntensity;
      }
    }
  };

  setConfig = (config) => {
    this.config = config;
    this.updateMaterialUniforms();
  };

  updateMaterialVectors = (delta, position, direction) => {
    if (this.materials.length > 0) {
      this.mousePosition.x = position.x;
      this.mousePosition.y = position.y;
      this.mousePosition.z = position.z;
      this.mousePosition.w = 1;

      this.mouseDirection.x = direction.x;
      this.mouseDirection.y = direction.y;
      this.mouseDirection.z = direction.z;

      for (let i = 0; i < this.materials.length; i++) {
        const material = this.materials[i];
        material.uniforms.uTime.value += this.autoUpdateAnimation ? delta : 0.0;
        material.uniforms.uMouse.value = this.mousePosition;
        material.uniforms.uDirection.value = this.mouseDirection;
      }
    }
  };

  update = (delta, position, direction) => {
    this.updateMaterialVectors(delta, position, direction);
  };

  dispose = () => {
    this.mousePosition = null;
    this.mouseDirection = null;
    this.waves = null;
    this.wavesPositionTarget = null;
    this.mesh?.clear();
    this.mesh = null;
  };
}
