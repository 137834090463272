import bezier from 'bezier-easing';

export const ease1 = bezier(0.66, 0.0, 0.34, 1.0);
export const ringsEase = bezier(0.0, 0.0, 0.4, 1.0);
export const cursorEase = bezier(0.0, 0.0, 0.0, 1.0);

export const easeInOut1 = bezier(0.4, 0.0, 0.1, 1.0);

export const easeOut1 = bezier(0.0, 0.0, 0.0, 1.0);

export const carouselButtonEase = bezier(0.0, 0.0, 0.0, 1.0);
export const carouselEaseInOut = bezier(0.54, 0.0, 0.08, 1.0);
export const carouselTitleEase = bezier(0.0, 0.0, 0.0, 1.0);
export const carouselZoomTransitionEase = bezier(0.56, 0.2, 0.44, 1.0);

export const explodedRenderEase = bezier(0.0, 0.0, 0.0, 1.0);
export const textTransitionEase = bezier(0.0, 0.0, 0.0, 1.0);
export const viewInSpaceEase = bezier(0.4, 0.0, 0.1, 1.0);

export const preloaderRingEase = bezier(0.4, 0.0, 0.1, 1.0);
export const preloaderLogoEase = bezier(0.0, 0.0, 0.0, 1.0);

export const productValueEaseInOut = bezier(0.4, 0.0, 0.1, 1.0);
export const productValueEaseOut = bezier(0.0, 0.0, 0.0, 1.0);
