import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import routes from '../../data/routes';

const useHeader = () => {
  const router = useRouter();
  let { userIsValid } = useSelector((state) => state.user);

  const shouldRenderHeader = [routes.Home.path, routes.Explore.path].includes(router.pathname) || !userIsValid;
  const shouldRenderMediaKitButton =
    (router.pathname === routes.Explore.path || router.pathname === routes.Home.path) && userIsValid;

  return {
    shouldRenderHeader,
    shouldRenderMediaKitButton
  };
};

export default useHeader;
