import React from 'react';
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group';
import styles from './Transition.module.scss';

import { useTransitionFix } from '../../utils/hooks/use-transition-fix';

import { TRANSITION_TIME } from '../../data/constants';

const TRANSITION_TIME_MS = TRANSITION_TIME * 1000;

const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0
  },
  entered: {
    transition: `opacity ${TRANSITION_TIME}s ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${TRANSITION_TIME}s ease-in-out`,
    opacity: 0
  },
  exited: {
    opacity: 0
  }
};

const Transition = ({ children, location, isActive }) => {
  const onExited = useTransitionFix();

  return (
    <TransitionGroup className={styles.Transition} style={{ position: 'relative' }}>
      <ReactTransition
        key={location}
        timeout={{
          enter: TRANSITION_TIME_MS,
          exit: TRANSITION_TIME_MS
        }}
      >
        {(status) => {
          if (status === 'exited') {
            onExited();
          }

          return (
            <div
              style={{
                ...getTransitionStyles[status]
              }}
              className={isActive ? '' : styles.hide}
            >
              {children}
            </div>
          );
        }}
      </ReactTransition>
    </TransitionGroup>
  );
};
export default Transition;
