import { createSlice } from '@reduxjs/toolkit';
import { cursorKeys } from '../../keys/cursor';

const initialState = {
  cursorState: cursorKeys.STATIC,
  hoverAreaId: null,
  isMouseDown: false,
  isHoldComplete: false
};

export const cursorSlice = createSlice({
  name: 'cursor',
  initialState: initialState,
  reducers: {
    setCursorState(state, action) {
      return { ...state, cursorState: action.payload };
    },
    setHoverAreaId(state, action) {
      return { ...state, hoverAreaId: action.payload };
    },
    setIsMouseDown(state, action) {
      return { ...state, isMouseDown: action.payload };
    },
    setIsHoldComplete(state, action) {
      return { ...state, isHoldComplete: action.payload };
    }
  }
});
