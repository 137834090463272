import { RingGeometry, Mesh, Group, Vector4, Vector2, Vector3 } from 'three';
import RingMaterial from './ring-material';
import VisualizationController, { AudioDataType } from '../../../audio/visualization-controller';
import { randomRange } from '../../../utils/utils';
import detect from '../../../../../../utils/detect';

export default class RingElements {
  constructor(props) {
    this.props = props;

    const { config, colorSet } = props;
    this.config = config;

    this.mesh = new Group();
    this.mousePosition = new Vector4();
    this.mouseDirection = new Vector2();
    this.ringsPositionTarget = [];
    this.visualizationIntensity = 1;
    this.visualizationSpread = 0;

    this.createGeometry(colorSet);
    this.updateMaterialUniforms();

    this.audioVisualization = null;
  }

  startVisualization = () => {
    if (detect.browser.isSafari) return;
    if (this.audioVisualization === null) {
      this.audioVisualization = new VisualizationController(AudioDataType.FREQUENCY, 128);
    }

    if (this.audioVisualization) {
      let audio = document.getElementById('ambient_audio_track');
      if (audio) {
        this.audioVisualization.start(audio, null);
      }
    }
  };

  stopVisualization = () => {
    if (this.audioVisualization) {
      this.audioVisualization.stop();
    }
  };

  setSound = (mute) => {
    if (this.audioVisualization) {
      this.audioVisualization.setSound(mute);
    }
  };

  createGeometry = (colorSet) => {
    const { elementColor, lightColor } = colorSet;

    this.material = RingMaterial({ color: elementColor, lightColor });
    this.range = [];

    var step = (this.config.maxRadius - this.config.minRadius) / this.config.rings;
    var currentRadius = this.config.minRadius;
    var frac = 1 / (this.config.rings - 1);

    for (let i = 0; i < this.config.rings; i++) {
      const thicknessOffset = Math.pow(frac * i, 3) * this.config.sizeVariation;
      const spaceOffset = Math.pow(frac * i, 3) * this.config.spaceVariation;
      const geometry = new RingGeometry(currentRadius, currentRadius + this.config.thickness + thicknessOffset, 256);
      const mesh = new Mesh(geometry, this.material);
      this.ringsPositionTarget.push(new Vector3(mesh.position.x, mesh.position.y, mesh.position.z));
      this.mesh.add(mesh);

      this.range.push(Math.round(randomRange(0, this.config.rings)));

      currentRadius += step + spaceOffset;
    }
  };

  getMaterialColor = () => {
    if (this.material) {
      return this.material.uniforms.uColor.value;
    } else {
      return new Vector4(0.0, 0.0, 0.0, 0.0);
    }
  };

  getMaterialLightColor = () => {
    if (this.material) {
      return this.material.uniforms.uLightColor.value;
    } else {
      return new Vector4(0.0, 0.0, 0.0, 0.0);
    }
  };

  updateMaterialUniforms = () => {
    if (this.material) {
      //pulse color
      this.material.uniforms.uPulseColorType.value = this.config.pulsePositionType === 'beat' ? 0.0 : 1.0;
      this.material.uniforms.uPulseColorAmount.value.x = this.config.pulseColorFrequency;
      this.material.uniforms.uPulseColorAmount.value.y = this.config.pulseColorIntensity;

      //pulse position
      this.material.uniforms.uPulsePositionType.value = this.config.pulsePositionType === 'beat' ? 0.0 : 1.0;
      this.material.uniforms.uPulseFrequency.value.x = this.config.pulsePositionFrequency.x;
      this.material.uniforms.uPulseFrequency.value.y = this.config.pulsePositionFrequency.y;
      this.material.uniforms.uPulseFrequency.value.z = this.config.pulsePositionFrequency.z;
      this.material.uniforms.uPulseFrequency.value.w = this.config.pulsePower;

      this.material.uniforms.uPulseIntensity.value.x = this.config.pulsePositionIntensity.x;
      this.material.uniforms.uPulseIntensity.value.y = this.config.pulsePositionIntensity.y;
      this.material.uniforms.uPulseIntensity.value.z = this.config.pulsePositionIntensity.z;
      // this.material.uniforms.uPulseIntensity.value.w = this.config.pulseIntensity;

      this.material.uniforms.uPulseSpread.value = this.config.pulseSpread;

      this.material.uniforms.uDistortionIntensity.value.x = this.config.distortionIntensity.x;
      this.material.uniforms.uDistortionIntensity.value.y = this.config.distortionIntensity.y;
      this.material.uniforms.uDistortionIntensity.value.z = this.config.distortionIntensity.z;
      this.material.uniforms.uDistortionIntensity.value.w = this.config.distortionIntensityMultiplier;
      this.material.uniforms.uDistortionAmount.value.x = this.config.distortionRadius;
      this.material.uniforms.uDistortionAmount.value.y = this.config.distortionSpread;
      this.material.uniforms.uDistortionAmount.value.z = this.config.distortionPower;
    }

    //light
    this.updateMaterialLightIntensity(this.config.lightIntensity);
    this.updateMaterialLightSpread(this.config.lightSpread);
    this.updateMaterialLightRadius(this.config.lightRadius);
  };

  setConfig = (config) => {
    this.config = config;
    this.updateMaterialUniforms();

    this.updateAmount(this.config.amount);
  };

  getOpacity = () => {
    if (this.material) {
      return this.material.uniforms.uOpacity.value;
    } else {
      return new Vector4();
    }
  };

  getAmount = () => {
    if (this.material) {
      return this.material.uniforms.uAmount.value;
    } else {
      return 0.0;
    }
  };

  getIdle = () => {
    if (this.material) {
      return this.material.uniforms.uIdle.value;
    } else {
      return 0.0;
    }
  };

  getPulseIntensity = () => {
    let intensity = 0.0;
    if (this.material) {
      intensity = this.material.uniforms.uPulseIntensity.value.w;
    } else {
      intensity = 0.5;
    }

    return intensity;
  };

  getPulseColorIntensity = () => {
    let intensity = 0.0;
    if (this.material) {
      intensity = this.material.uniforms.uPulseColorAmount.value.y;
    } else {
      intensity = 0.5;
    }

    return intensity;
  };

  updateMaterialPulseTime = (time) => {
    if (!this.autoUpdateAnimation && this.material) {
      this.material.uniforms.uPulseTime.value = time;
    }
  };

  updateOpacity = (opacity) => {
    if (this.material) {
      this.material.uniforms.uOpacity.value = opacity;
    }
  };

  updateAspect = (aspect) => {
    if (this.material) {
      this.material.uniforms.uAspect.value = aspect;
    }
  };

  updateMaterialColor = (color) => {
    if (this.material) {
      this.material.uniforms.uColor.value = color;
    }
  };

  updateMaterialLightColor = (color) => {
    if (this.material) {
      this.material.uniforms.uLightColor.value = color;
    }
  };

  updateMaterialLightIntensity = (intensity) => {
    if (this.material) {
      this.material.uniforms.uLightIntensity.value = intensity;
    }
  };

  updateMaterialLightRadius = (radius) => {
    if (this.material) {
      this.material.uniforms.uLightRadius.value = radius;
    }
  };

  updateMaterialLightSpread = (spread) => {
    if (this.material) {
      this.material.uniforms.uLightSpread.value = spread;
    }
  };

  updateDistortion = (distortion) => {
    if (this.material) {
      this.material.uniforms.uDistortionAmount.value = distortion;
    }
  };

  updatePulseIntensity = (intensity) => {
    if (this.material) {
      this.material.uniforms.uPulseIntensity.value.w = intensity;
    }
  };

  updateAmount = (amount) => {
    if (this.material) {
      this.material.uniforms.uAmount.value = amount;
    }
  };

  updateIdle = (amount) => {
    if (this.material) {
      this.material.uniforms.uIdle.value = amount;
    }
  };

  updatePulseColorIntensity = (intensity) => {
    if (this.material) {
      this.material.uniforms.uPulseColorAmount.value.y = intensity;
    }
  };

  updateMaterialVectors = (delta, position, direction) => {
    if (this.material) {
      this.mousePosition.x = position.x;
      this.mousePosition.y = position.y;
      this.mousePosition.z = position.z;
      this.mousePosition.w = 1;

      this.mouseDirection.x = direction.x;
      this.mouseDirection.y = direction.y;
      this.mouseDirection.z = direction.z;

      this.material.uniforms.uTime.value += delta;
      this.material.uniforms.uMouse.value = this.mousePosition;
      this.material.uniforms.uDirection.value = this.mouseDirection;
    }
  };

  setRingsPosition = () => {
    if (this.audioVisualization) {
      let audioData = this.audioVisualization.getAudioData();
      if (audioData) {
        let specIndex = 5;
        for (let i = 0; i < this.config.rings; i++) {
          if (i < audioData.length) {
            const element = this.mesh.children[i];
            const data = audioData.data[(specIndex + this.range[i]) % 3];
            const pos = (data - 128) / 256;
            const positionTarget = this.ringsPositionTarget[i];
            positionTarget.z =
              pos * this.config.positionIntensity * this.visualizationIntensity +
              i * this.range[i] * 0.1 * this.visualizationSpread;
            element.position.lerp(positionTarget, this.config.positionDumping);
          }
        }
      }
    }
  };

  update = (delta, position, direction) => {
    this.updateMaterialVectors(delta, position, direction);
    this.setRingsPosition();
  };

  dispose = () => {
    if (this.audioVisualization) {
      this.audioVisualization.stop();
      this.audioVisualization.dispose();
      this.audioVisualization = null;
    }

    this.mousePosition = null;
    this.mouseDirection = null;
    this.rings = null;
    this.ringsPositionTarget = null;
    this.mesh?.clear();
    this.mesh = null;
  };
}
