import { Color, LinearFilter, LinearMipmapLinearFilter } from 'three';

import MainScene from '../scenes/main-scene';
import LandingScene from '../scenes/landing-scene';
import ProductScene from '../scenes/product-scene';
import RingElements from '../scenes/elements/ring/ring-elements';
import WaveElements from '../scenes/elements/waves/wave-elements';
import ProductElements from '../scenes/elements/product/product-elements';

import { Debug as Debug0 } from './debug';
import HotspotElements from '../scenes/elements/product/hotspot-elements';

export const Debug = new Debug0();

export const PROPS = {};

export const GRAPHICS_HIGH = 'high';
export const GRAPHICS_NORMAL = 'normal';
export const GRAPHICS_LOW = 'low';
export const GRAPHICS_MODES = [GRAPHICS_HIGH, GRAPHICS_NORMAL, GRAPHICS_LOW];
export const GRAPHICS = [GRAPHICS_LOW, GRAPHICS_NORMAL, GRAPHICS_HIGH];

export const GRAPHICS_BREAKPOINTS = {
  [GRAPHICS_HIGH]: {
    maxHeight: 1200,
    drawTextureSize: 64
  },

  [GRAPHICS_NORMAL]: {
    maxHeight: 600,
    drawTextureSize: 32
  },

  [GRAPHICS_LOW]: {
    maxHeight: 300,
    drawTextureSize: 32
  }
};

export const GRAPHICS_TIERS = {
  [GRAPHICS_HIGH]: {
    pixelRatio: 1.5,
    antialias: true
  },
  [GRAPHICS_NORMAL]: {
    pixelRatio: 1,
    antialias: true
  },
  [GRAPHICS_LOW]: {
    pixelRatio: 1,
    antialias: true
  }
};

export const IPHONE_6_GPU = 'Apple A8 GPU';
export const IPHONE_6S_GPU = 'Apple A9 GPU';

export const SPEC = {
  [GRAPHICS_HIGH]: {
    precision: 'highp',
    unsupportedGPU: [IPHONE_6_GPU, IPHONE_6S_GPU]
  },
  [GRAPHICS_NORMAL]: {
    precision: 'mediump',
    unsupportedGPU: [IPHONE_6_GPU, IPHONE_6S_GPU]
  },
  [GRAPHICS_LOW]: {
    precision: 'lowp',
    unsupportedGPU: [IPHONE_6_GPU, IPHONE_6S_GPU]
  }
};

export const ANIMATIONS_TYPE = {
  ROTATION: 0,
  REPEL: 1
};

export const PULSE_TYPES = ['beat', 'pulse'];

export const PULSE_INTENSITY_TYPES = ['low', 'high'];

export const ConfigTypes = ['low', 'high'];

export const COLOR_NAMES = {
  DARKBLUE: 'darkBlue',
  PURPLE: 'purple',
  DARK_PURPLE: 'darkPurple',
  TEASER: 'teaser',
  BEAM_LANDING: 'beamLanding',
  BEAM_HOME: 'beamHome'
};

export const COLOR_SETS = {
  [COLOR_NAMES.DARKBLUE]: {
    backgroundColor: 0x1f355a,
    elementColor: 0x13274a,
    lightColor: 0xa76fff
  },
  [COLOR_NAMES.PURPLE]: {
    backgroundColor: 0xc1b1f2,
    elementColor: 0xa76fff,
    lightColor: 0xffffff
  },
  [COLOR_NAMES.DARK_PURPLE]: {
    backgroundColor: 0x9058ea,
    elementColor: 0x6432b4,
    lightColor: 0xd6c8ff
  },
  [COLOR_NAMES.TEASER]: {
    backgroundColor: 0x1f355a,
    elementColor: 0xa76fff,
    lightColor: 0xc8a6ff
  },
  [COLOR_NAMES.BEAM_LANDING]: {
    backgroundColor: 0x662f3c,
    elementColor: 0xb87a89,
    lightColor: 0xfebca5
  },
  [COLOR_NAMES.BEAM_HOME]: {
    backgroundColor: 0x662f3c,
    elementColor: 0x552833,
    lightColor: 0x6d444e
  }
};

export const ELEMENT_TYPES = {
  RINGS: 'rings',
  WAVES: 'waves',
  PRODUCT: 'product'
};

export const RingsConfigLow = {
  type: ELEMENT_TYPES.RINGS,
  //rings
  rings: 14,
  minRadius: 1.3,
  maxRadius: 3.3,
  thickness: 0.008,
  sizeVariation: 0.018,
  spaceVariation: 0.1,
  //color
  colorSet: COLOR_NAMES.BEAM_HOME,
  //light
  lightIntensity: 0.5, //0.5,
  lightRadius: 3.0,
  lightSpread: 0.0,
  //animation
  pulseColorType: 'beat',
  pulseColorFrequency: 1.6,
  pulseColorIntensity: 0.4,
  pulsePositionType: 'beat',
  pulsePower: 1.1,
  pulseIntensityType: 'low',
  pulseIntensity: 1.2,
  pulseSpread: 0.2,
  pulsePositionFrequency: { x: 3.141516, y: 3.141516, z: 3.141516 },
  pulsePositionIntensity: { x: 2, y: 2, z: 2 },
  //interaction
  distortionIntensity: { x: 2.3, y: 2.3, z: 2.3 },
  distortionIntensityMultiplier: 0.2,
  distortionZ: 0.6,
  distortionRadius: 3.0,
  distortionSpread: 0.0,
  distortionPower: 1.1,
  distortionDumping: 0.95,
  distortionSmoothness: 0.95,
  // camera
  cameraFOV: 65,
  cameraRotation: { x: 0.4, y: 0.4 },
  cameraSpeed: 1,
  cameraZoom: 5,
  //audio
  positionIntensity: 0.6,
  positionDumping: 0.45,
  amount: 0
};

export const RingsConfigHigh = {
  type: ELEMENT_TYPES.RINGS,
  //rings
  rings: 14,
  minRadius: 1.3,
  maxRadius: 3.3,
  thickness: 0.008,
  sizeVariation: 0.018,
  spaceVariation: 0.1,
  //color
  colorSet: COLOR_NAMES.BEAM_HOME,
  //light
  lightIntensity: 1.94, //0.5,
  lightRadius: 4.0,
  lightSpread: 0.0,
  //animation
  pulseColorType: 'beat',
  pulseColorFrequency: 1.6,
  pulseColorIntensity: 0.25,
  pulsePositionType: 'beat',
  pulsePower: 3.2,
  pulseIntensityType: 'low',
  pulseIntensity: 2.1,
  pulseSpread: 0.2,
  pulsePositionFrequency: { x: 3.141516, y: 3.141516, z: 3.141516 },
  pulsePositionIntensity: { x: 2, y: 2, z: 2 },
  //interaction
  distortionIntensity: { x: 2.3, y: 2.3, z: 2.3 },
  distortionIntensityMultiplier: 0.02,
  distortionZ: 2.64,
  distortionRadius: 4.8,
  distortionSpread: -0.1,
  distortionPower: 1.3,
  distortionDumping: 0.95,
  distortionSmoothness: 0.95,
  // camera
  cameraFOV: 65,
  cameraRotation: { x: 0.4, y: 0.4 },
  cameraSpeed: 1,
  cameraZoom: 5,
  //audio
  positionIntensity: 0.6,
  positionDumping: 0.45,
  amount: 0
};

export const WavesConfig = {
  type: ELEMENT_TYPES.WAVES,
  //waves
  lines: 3,
  startY: 0.0,
  spaceVariation: 0.09,
  lineWidth: 16,
  vpu: 32,
  thickness: 0.008,
  zStep: 0.1,
  //color
  colorSet: COLOR_NAMES.BEAM_LANDING,
  //light
  lightIntensity: 5,
  lightRadius: 2.0,
  lightSpread: 0.0,
  //animation
  offset: { x: 10.5, y: 0.5, z: 0.1, w: 0.1 },
  waveIntensityX: 2.0,
  waveIntensityY: 2.0,
  waveSpeedIn: 2.0,
  waveSpeedOut: 3.0,
  noiseSpeed: 0.4,
  noiseTile: 2.0,
  noiseOffsetY: 0.8,
  noiseIntensity: 0.5,
  //interaction
  distortionIntensity: 2.3,
  distortionRadius: 3.0,
  distortionSpread: 0.0,
  distortionPower: 1.1,
  distortionDumping: 0.9,
  distortionSmoothness: 0.95,
  amount: 0
};

export const ProductConfig = {
  type: ELEMENT_TYPES.PRODUCT,
  colorSet: COLOR_NAMES.BEAM_HOME,
  cameraPosition: [0, 30, 0],
  cameraPositionOffset: [10, -15, 10],
  modelId: 'sonos-beam'
};

export const INITIAL_PRODUCT_ROTATION = Math.PI / -6;
export const PRODUCT_CAMERA_DAMPING = 0.15;
export const PRODUCT_CAMERA_ORBIT = 66.7;
export const PRODUCT_CAMERA_ZOOM = 0.75;

export const SCENE_TYPES = {
  MAIN: 'main',
  LANDING: 'landing',
  PRODUCT: 'product'
};

export const PRODUCT_TYPES = {
  BLACK: 'BEAM_BLACK',
  WHITE: 'BEAM_WHITE',
  EMPTY: 'EMPTY'
};

export const SCENES = {
  [SCENE_TYPES.MAIN]: {
    config: RingsConfigLow,
    scene: MainScene,
    element: RingElements
  },
  [SCENE_TYPES.LANDING]: {
    config: WavesConfig,
    scene: LandingScene,
    element: WaveElements
  },
  [SCENE_TYPES.PRODUCT]: {
    config: ProductConfig,
    scene: ProductScene,
    element: ProductElements
  }
};

export const DEFAULT_SCENE = SCENE_TYPES.LANDING;
export const DEFAULT_PRODUCT = PRODUCT_TYPES.EMPTY;

const HOTSPOT_SIZE = [2, 2, 2];
export const HOTSPOT_RINGS = 2;
export const HOTSPOTS = [
  {
    id: 'P1',
    position: [25, 3, 6.5],
    scale: HOTSPOT_SIZE,
    texture: 'hotspot',
    ring: 'hotspot-ring',
    element: HotspotElements
  },
  {
    id: 'P2',
    position: [-7, 8, 0.5],
    scale: HOTSPOT_SIZE,
    texture: 'hotspot',
    ring: 'hotspot-ring',
    element: HotspotElements
  },
  {
    id: 'P3',
    position: [0, 8, 0.5],
    scale: HOTSPOT_SIZE,
    texture: 'hotspot',
    ring: 'hotspot-ring',
    element: HotspotElements
  },
  {
    id: 'P4',
    position: [7, 8, 0.5],
    scale: HOTSPOT_SIZE,
    texture: 'hotspot',
    ring: 'hotspot-ring',
    element: HotspotElements
  },
  {
    id: 'P5',
    position: [0, 1, 6.5],
    scale: HOTSPOT_SIZE,
    texture: 'hotspot',
    ring: 'hotspot-ring',
    element: HotspotElements
  },
  {
    id: 'P6',
    position: [8, 2, -7],
    scale: HOTSPOT_SIZE,
    texture: 'hotspot',
    ring: 'hotspot-ring',
    element: HotspotElements
  },
  {
    id: 'P7',
    position: [-4, 0, -5],
    scale: HOTSPOT_SIZE,
    texture: 'hotspot',
    ring: 'hotspot-ring',
    element: HotspotElements
  }
];

export const MODEL_CONFIG = [
  {
    id: 'BEAM_BLACK',
    position: [3, 0, -8.5],
    rotation: [Math.PI / -2, 0, 0],
    materialConfig: [
      {
        key: 'BODY_BLACK',
        envMap: 'env-map-01',
        config: {
          envMapIntensity: 0.5,
          roughness: 1,
          metalness: 0.1,
          emissive: new Color(0x18181b),
          transparent: true
        },
        textureConfig: {
          magFilter: LinearFilter,
          minFilter: LinearMipmapLinearFilter
        }
      },
      {
        key: 'GRILLE_BLACK',
        envMap: 'env-map-01',
        config: {
          envMapIntensity: 0,
          roughness: 1,
          metalness: 0,
          color: new Color(0x202020),
          emissive: new Color(0x181819),
          transparent: true
        }
      },
      {
        key: 'GRILLE_BLACK_R',
        envMap: 'env-map-01',
        config: {
          envMapIntensity: 0,
          roughness: 1,
          metalness: 0,
          color: new Color(0x202020),
          emissive: new Color(0x181819),
          transparent: true
        }
      }
    ],
    materialOverride: [
      {
        key: 'Black_Sonos',
        config: {
          emissive: new Color(0x1b1b1b),
          roughness: 0.5,
          metalness: 0.0,
          roughnessMap: null,
          transparent: true
        },
        scale: [1, 2, 1],
        offset: [0, 136.7, 0]
      },
      {
        key: 'Black_Top_Cover',
        scale: [1.002, 1.008, 1],
        offset: [0, 0.8, -1.0]
      }
    ],
    lightIntensity: 2
  },
  {
    id: 'BEAM_WHITE',
    position: [3, 0, -8.5],
    rotation: [Math.PI / -2, 0, 0],
    materialConfig: [
      {
        key: 'BODY_WHITE',
        envMap: 'env-map-01',
        config: {
          envMapIntensity: 0.8,
          roughness: 1,
          metalness: 0,
          color: new Color(0xbbbbbb),
          transparent: true
        },
        textureConfig: {
          magFilter: LinearFilter,
          minFilter: LinearMipmapLinearFilter
        }
      },
      {
        key: 'GRILLE_WHITE',
        envMap: 'env-map-01',
        config: {
          envMapIntensity: 0.1,
          roughness: 1,
          metalness: 0,
          color: new Color(0xe8e8e9),
          emissive: new Color(0x28282b),
          transparent: true
        },
        textureConfig: {
          magFilter: LinearFilter,
          minFilter: LinearMipmapLinearFilter
        }
      },
      {
        key: 'GRILLE_WHITE_R',
        envMap: 'env-map-01',
        config: {
          envMapIntensity: 0.1,
          roughness: 1,
          metalness: 0,
          color: new Color(0xe8e8e9),
          emissive: new Color(0x28282b),
          transparent: true
        }
      }
    ],
    materialOverride: [
      {
        key: 'White_Sonos',
        config: {
          emissive: new Color(0x111112),
          roughness: 0.3,
          metalness: 0.8,
          roughnessMap: null,
          transparent: true
        },
        scale: [1, 2, 1],
        offset: [0, 136.7, 0]
      },
      {
        key: 'White_Top_Cover',
        scale: [1.002, 1.008, 1],
        offset: [0, 0.8, -1.0]
      }
    ],
    lightIntensity: 0.667
  }
];
