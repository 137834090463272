export default {
  product: {
    header: 'Experience Beam',
    subhead: 'The compact smart soundbar that enriches your listening experience at home.',
    description:
      'Enjoy rich, high-definition sound for anything you can stream. Beam enhances your home theater with new innovations built into the same compact design that blends seamlessly into your home.'
  },
  mediaKit: {
    backgroundImg: require('../assets/images/mediakit.jpg'),
    text: 'Explore the media kit',
    href: '/press'
  },
  fullWidthImage: {
    src: require('../assets/images/reviews-hero.jpg'),
    alt: 'Sonos Beam product image'
  },
  titleImageCombo: {
    header:
      "A great soundbar should look, feel, and sound effortless. The new Beam delivers a sound that's pristine and very defined - its unique low and top end are music to my ears.",
    subhead: '- Manny Marroquin, Grammy Award-winning music producer and Sonos Soundboard member',
    image1: {
      src: require('../assets/images/reviews-image-1.jpg'),
      alt: 'Sonos Beam product image'
    },
    image2: {
      src: require('../assets/images/reviews-image-2.jpg'),
      alt: 'Sonos Beam product image'
    }
  }
};
