const userNameStorageKey = 'userName';
const videoProgressKey = 'videoProgress';
const tokenKey = 'token';
const cookieModalKey = 'seenCookiesModal';

/* ========================================
Modal 
======================================== */

export const setSeenCookiesModal = (seenCookiesModal) => {
  localStorage.setItem(cookieModalKey, seenCookiesModal);
};

export const getSeenCookiesModal = () => {
  const value = localStorage.getItem(cookieModalKey);
  return value ? value : false;
};

/* ========================================
Username tings
======================================== */

export const setUserName = (userName) => {
  localStorage.setItem(userNameStorageKey, userName);
};

export const getUserName = () => {
  const value = localStorage.getItem(userNameStorageKey);
  return value;
};

export const deleteUserName = () => {
  localStorage.removeItem(userNameStorageKey);
};

/* ========================================
API Token tings
======================================== */

export const getApiToken = () => {
  const value = localStorage.getItem(tokenKey);
  return value ? value : null;
};

/* ========================================
Video Progress
======================================== */

export const setVideoProgressLocalStorage = (progressInSeconds) => {
  localStorage.setItem(videoProgressKey, progressInSeconds);
};

export const getVideoProgressLocalStorage = () => {
  return localStorage.getItem(videoProgressKey);
};

export const deleteVideoProgress = () => {
  localStorage.removeItem(videoProgressKey);
};
