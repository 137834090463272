import {
  CustomBlending,
  DoubleSide,
  Object3D,
  OneFactor,
  OneMinusSrcAlphaFactor,
  Sprite,
  SpriteMaterial,
  SrcAlphaFactor,
  Vector3
} from 'three';
import { gsap } from 'gsap';

import vertexShader from '../../../glsl/sprite.vert';
import fragmentShader from '../../../glsl/sprite.frag';
import { HOTSPOT_RINGS } from '../../../utils/constants';
import { isTouchDevice } from '../../../../../../utils/detect';

export default class HotspotElements {
  constructor(props) {
    this.props = props;

    this.init();
  }

  init() {
    const { texture, ringTexture, position, scale, id } = this.props;

    this.initialScale = new Vector3().fromArray(scale);
    this.mesh = new Object3D();
    this.id = id;

    this.material = this.getMaterial(texture, 0.01);

    this.sprite = new Sprite(this.material);

    this.sprite.position.fromArray(position);
    this.sprite.scale.copy(this.initialScale);

    this.sprite.userData = {
      el: this
    };

    this.makeRings(ringTexture, HOTSPOT_RINGS);

    this.mesh.add(this.sprite);
  }

  makeRings(texture, n) {
    const { position } = this.props;

    const fract = 1 / n;
    this.rings = [];
    this.ringsOpacity = 0;
    this.ringsScale = 1;

    for (let i = 0; i < n; i++) {
      const material = this.getMaterial(texture, 0.05, SrcAlphaFactor, OneFactor, 0xfebca5);
      const sprite = new Sprite(material);
      const progress = 0 + i * fract;

      sprite.position.fromArray(position);
      sprite.scale.copy(this.initialScale).multiplyScalar(0);
      sprite.material.opacity = 0;

      sprite.userData = {
        el: this
      };

      const ring = {
        material,
        sprite,
        progress
      };

      this.mesh.add(sprite);
      this.rings.push(ring);
    }
  }

  getMaterial(texture, alphaTest = 0, blendSrc = SrcAlphaFactor, blendDst = OneMinusSrcAlphaFactor, color = 0xffffff) {
    const material = new SpriteMaterial({
      map: texture.texture,
      transparent: true,
      side: DoubleSide,
      alphaTest,
      blending: CustomBlending,
      blendSrc,
      blendDst,
      color,
      opacity: 0
    });

    material.onBeforeCompile = (shader) => {
      shader.vertexShader = vertexShader;
      shader.fragmentShader = fragmentShader;
    };

    return material;
  }

  handleMouseEnter() {
    if (isTouchDevice) return;
    this.isHover = true;
    gsap.to(this.sprite.scale, {
      x: this.initialScale.x * 1.2,
      y: this.initialScale.y * 1.2,
      z: this.initialScale.z * 1.2,
      duration: 0.2
    });

    gsap.to(this, { ringsScale: 1.2, duration: 0.2 });
  }

  handleMouseLeave() {
    if (isTouchDevice) return;
    this.isHover = false;
    gsap.to(this.sprite.scale, {
      x: this.initialScale.x,
      y: this.initialScale.y,
      z: this.initialScale.z,
      duration: 0.2
    });

    gsap.to(this, { ringsScale: 1, duration: 0.2 });
  }

  open(duration = 0.334, ease = 'power1.inout', delay = 0) {
    gsap.to(this.material, {
      rotation: Math.PI / -4,
      duration,
      delay,
      ease
    });
  }

  close(duration = 0.334, ease = 'power1.inout', delay = 0) {
    gsap.to(this.material, {
      rotation: 0,
      duration,
      delay,
      ease
    });
  }

  show(duration = 0.667, ease = 'linear', delay = 0) {
    gsap.to(this.sprite.material, { opacity: 1, duration, delay, ease, overwrite: true });
    gsap.to(this, { ringsOpacity: 1, ringsScale: 1, duration, delay, ease, overwrite: true });
    gsap.to(this.sprite.scale, {
      x: this.initialScale.x,
      y: this.initialScale.y,
      z: this.initialScale.z,
      duration,
      delay,
      ease,
      overwrite: true
    });
  }

  hide(duration = 0.667, ease = 'linear', delay = 0, force = false) {
    gsap.to(this.sprite.material, { opacity: 0, duration, delay, ease, overwrite: true });
    gsap.to(this, { ringsOpacity: 0, ringsScale: 0, duration, delay, ease, overwrite: true });
    gsap.to(this.sprite.scale, { x: 0, y: 0, z: 0, duration, delay, ease, overwrite: true });
    if (force) {
      this.rings.forEach((el) => {
        gsap.to(el.sprite.material, { opacity: 0, duration, delay, ease, overwrite: true });
        gsap.to(el.sprite.scale, { x: 0, y: 0, z: 0, duration, delay, ease, overwrite: true });
      });
    }
  }

  update(delta) {
    this.rings.forEach((el) => {
      el.progress += delta * 0.33;
      if (el.progress >= 1) {
        el.progress = 0;
      }

      el.sprite.scale.copy(this.initialScale).multiplyScalar(this.ringsScale * (0.8 + el.progress));
      el.sprite.material.opacity = this.ringsOpacity * (1 - el.progress);
    });
  }

  dispose() {}
}
