import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import dynamic from 'next/dynamic';

import 'normalize.css';
import '../styles/global.scss';

import PageGateWrapper from '../components/PageGateWrapper/PageGateWrapper';
import Layout from '../components/Layout/Layout';

import { CopyProvider } from '../utils/hooks/use-copy';
import copyData from '../copy';

import { store } from '../redux';
import detect, { isTouchDevice, isMobile } from '../utils/detect';

const isBrowser = typeof window !== 'undefined';

const WebGL = dynamic(() => import('../components/WebGL/WebGL'), {
  ssr: false
});

if (isBrowser) {
  require('default-passive-events');
  require('focus-visible');
}
const RotateScreen = dynamic(() => import('../components/RotateScreen/RotateScreen'), { ssr: false });
const SplashScreen = dynamic(() => import('../components/SplashScreen/SplashScreen'), { ssr: false });
const MobileScreen = dynamic(() => import('../components/MobileScreen/MobileScreen'), { ssr: false });

// This default export is required in a new `pages/_app.js` file.
function App({ Component, pageProps }) {
  const { isUnsupported, ...componentProps } = pageProps;

  // Causing render issues
  // TODOs for kin: re-investigate approach for webgl render
  // const useWebGL = ![routes.Press.path, routes.Reviews.path].includes(router.pathname);

  useEffect(() => {
    if (isBrowser) {
      if (process.env.NODE_ENV !== 'production' && window.location.href.indexOf('?nostat') === -1) {
        require('@jam3/stats')();
      }

      const { device, browser } = detect;
      const classes = [isTouchDevice ? 'touch-device' : '', device.getType(), browser.getName()].filter((className) =>
        Boolean(className)
      );
      document.body.className = [...document.body.className.split(' '), ...classes].filter(Boolean).join(' ');
    }
  }, []);

  return isUnsupported ? (
    <Component {...componentProps} />
  ) : (
    <Provider store={store}>
      <CopyProvider value={copyData}>
        <WebGL />
        {isMobile ? <MobileScreen /> : null}
        <RotateScreen />
        {!isMobile ? <SplashScreen /> : null}
        <PageGateWrapper>
          <Layout>
            <Component {...componentProps} />
          </Layout>
        </PageGateWrapper>
      </CopyProvider>
    </Provider>
  );
}

export default App;
