export const TRANSITION_TIME = 0.6;

export const SELECTED_COLORS = {
  WHITE: 'white',
  BLACK: 'black'
};

export const COLORS = {
  PEACH: '#febca5',
  WHITE: '#ffffff'
};
