import { useCallback, useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import wait from '@jam3/wait';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { TRANSITION_TIME } from '../../data/constants';
import { setWipeDirection, setWipeIsAnimating } from '../../redux';
import pageDirections, { wipePositionKeys, getPageDirectionKey, defaultDuration } from '../../keys/wipe';

const useWipeTransitionListener = (listen) => {
  const [history, setHistory] = useState([]);
  const router = useRouter();
  const dispatch = useDispatch();
  const { wipeRef, wipeIsAnimating } = useSelector((state) => state.wipe);
  const wipeEl = useRef(null);
  const wipeIsAnimatingRef = useRef();

  //Hide wipe
  const visuallyHideWipe = useCallback(() => {
    if (!wipeEl.current) return;

    gsap.set(wipeEl.current, { autoAlpha: 0 });
  }, [wipeEl]);

  //Query for wipe ref
  useEffect(() => {
    if (!wipeRef) return;

    const el = document.getElementById(wipeRef);
    if (el) {
      wipeEl.current = el;
    }
  }, [wipeRef]);

  useEffect(() => {
    const pathname = router.pathname;
    if (history.length > 0) {
      setHistory((prev) => {
        const oldHistory = prev;

        if (oldHistory[oldHistory.length - 1] === pathname) return oldHistory;

        return [...prev, pathname];
      });
    } else {
      setHistory([pathname]);
    }
  }, [router, history, setHistory]);

  const getEndWipeCoordinates = useCallback((startPos) => {
    let coords;
    switch (startPos) {
      case wipePositionKeys.TOP:
        coords = { x: 0, y: '101%' };
        break;
      case wipePositionKeys.BOTTOM:
        coords = { x: 0, y: '-101%' };
        break;
      case wipePositionKeys.LEFT:
        coords = { x: '101%', y: 0 };
        break;
      case wipePositionKeys.RIGHT:
        coords = { x: '-101%', y: 0 };
        break;
      default:
        coords = { x: '-101%', y: 0 };
        break;
    }

    return coords;
  }, []);

  const getStartWipeCoordinates = useCallback((startPos) => {
    let coords;
    switch (startPos) {
      case wipePositionKeys.TOP:
        coords = { x: 0, y: '-101%' };
        break;
      case wipePositionKeys.BOTTOM:
        coords = { x: 0, y: '101%' };
        break;
      case wipePositionKeys.LEFT:
        coords = { x: '-101%', y: 0 };
        break;
      case wipePositionKeys.RIGHT:
        coords = { x: '101%', y: 0 };
        break;
      default:
        coords = { x: '-101%', y: 0 };
        break;
    }

    return coords;
  }, []);

  //Animate wipe
  const animateWipeIn = useCallback(
    async (startPos, middleCallback, wipeDuration) => {
      if (wipeIsAnimatingRef.current) return;

      dispatch(setWipeIsAnimating(true));

      const endCoords = getEndWipeCoordinates(startPos);
      const startCoords = getStartWipeCoordinates(startPos);

      gsap.set(wipeEl.current, {
        autoAlpha: 1,
        ...startCoords
      });

      const tl = gsap.timeline({
        onComplete: () => {
          visuallyHideWipe();
          dispatch(setWipeDirection(null));

          //Set wipe is animating back to false
          dispatch(setWipeIsAnimating(false));
        }
      });

      const duration = wipeDuration ? wipeDuration : defaultDuration;
      const delay = TRANSITION_TIME;

      await wait(20); // needs a tick for initial animation

      tl.to(wipeEl.current, {
        x: 0,
        y: 0,
        duration
      });

      const inMiddleFunc = () => {
        if (middleCallback) {
          middleCallback();
        }
      };

      tl.add(inMiddleFunc);

      tl.to(wipeEl.current, {
        ...endCoords,
        duration,
        delay
      });
    },
    [wipeEl, getStartWipeCoordinates, getEndWipeCoordinates, visuallyHideWipe, dispatch]
  );

  //Need to detect whether or not wipe is animating
  //without re-running animate function
  //so we store the value in a ref when it changes
  //and refer to ref only
  useEffect(() => {
    wipeIsAnimatingRef.current = wipeIsAnimating;
  }, [wipeIsAnimating]);

  //Animate out wipe
  const animateOnPathChange = useCallback(
    (pathComingFrom, goToPath) => {
      if (!wipeEl.current) return;

      const pageDirectionKey = getPageDirectionKey(pathComingFrom, goToPath);
      const options = pageDirections[pageDirectionKey];

      if (!options) return;

      animateWipeIn(options.startPos);
    },
    [animateWipeIn, wipeEl]
  );

  //Listen for history
  useEffect(() => {
    if (!listen) return;

    if (!history.length) return;
    if (history.length < 2) return;

    const pathComingFrom = history[history.length - 2];
    const pathGoingTo = history[history.length - 1];

    animateOnPathChange(pathComingFrom, pathGoingTo);
  }, [history, animateOnPathChange, listen]);

  return {
    animateWipeIn
  };
};

export default useWipeTransitionListener;
