import { createSlice } from '@reduxjs/toolkit';
import { SELECTED_COLORS } from '../../data/constants';

const initialState = {
  prevRoute: null,
  isWebpSupported: true,
  selectedColor: SELECTED_COLORS.WHITE,
  activePersona: null
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setPrevRoute(state, action) {
      return { ...state, prevRoute: action.prevRoute };
    },
    setIsWebpSupported(state, action) {
      return { ...state, isWebpSupported: action.payload };
    },
    setSelectedColor(state, action) {
      return { ...state, selectedColor: action.payload };
    },
    setActivePersona(state, action) {
      return { ...state, activePersona: action.payload };
    }
  }
});
