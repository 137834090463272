import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import checkProps from '@jam3/react-check-extra-props';
import gsap from 'gsap';

import styles from './MediaKitButton.module.scss';
import BaseButton from '../BaseButton/BaseButton';
import HoverArea from '../HoverArea/HoverArea';

import routes from '../../data/routes';

import { cursorKeys } from '../../keys/cursor';
import { TRANSITION_TIME } from '../../data/constants';
import { setProduct } from '../../redux';
import { PRODUCT_TYPES } from '../WebGL/WebGLApp/utils/constants';

function MediaKitButton({ className }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const buttonRef = useRef(null);

  useEffect(() => {
    gsap.to(buttonRef.current, {
      opacity: 1,
      duration: 1,
      delay: 0.1 + TRANSITION_TIME * 2.5,
      ease: 'linear'
    });
  }, []);

  return (
    <div className={classnames(styles.MediaKitButton, className)} ref={buttonRef}>
      <HoverArea cursorType={cursorKeys.FOCUS}>
        <BaseButton
          onClick={() => {
            router.push(routes.Press.path);
            dispatch(setProduct(PRODUCT_TYPES.EMPTY));
          }}
          className={styles.button}
        >
          View Media Kit
        </BaseButton>
      </HoverArea>
    </div>
  );
}

MediaKitButton.propTypes = checkProps({
  className: PropTypes.string,
  onClick: PropTypes.func
});

MediaKitButton.defaultProps = {};

export default memo(MediaKitButton);
