export default {
  title: 'This website uses cookies',
  description:
    'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners. ',
  learnMore: {
    label: 'Learn more.',
    href: 'https://www.sonos.com/en-us/legal/privacy#legal-privacy-cookies-cookies'
  },
  buttonLabel: 'Accept Cookies',
  settings: {
    label: 'Cookies Settings',
    href: 'https://www.sonos.com/en-us/legal/privacy#legal-privacy-cookies-settings-headline'
  }
};
