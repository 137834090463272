import routes from '../data/routes';

export const defaultDuration = 0.2;

export const wipePositionKeys = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

const pageDirections = {
  default: {
    startPos: wipePositionKeys.BOTTOM,
    duration: defaultDuration
  }
};

const removeSlash = (str) => str.replace(/\\|\//g, '');

export const getPageDirectionKey = (startPath, endPath) => {
  return `${removeSlash(startPath)}${removeSlash(endPath)}`;
};

const registerPageWipeOptions = (startPage, endPage, options) => {
  const pageDirectionKey = getPageDirectionKey(startPage, endPage);
  pageDirections[pageDirectionKey] = options;
};

registerPageWipeOptions(routes.Home.path, routes.Press.path, {
  startPos: wipePositionKeys.LEFT
});

registerPageWipeOptions(routes.Explore.path, routes.Press.path, {
  startPos: wipePositionKeys.LEFT
});

registerPageWipeOptions(routes.Explore.path, routes.Reviews.path, {
  startPos: wipePositionKeys.LEFT
});

registerPageWipeOptions(routes.Reviews.path, routes.Explore.path, {
  startPos: wipePositionKeys.RIGHT
});

registerPageWipeOptions(routes.Reviews.path, routes.Press.path, {
  startPos: wipePositionKeys.RIGHT
});

registerPageWipeOptions(routes.Press.path, routes.Reviews.path, {
  startPos: wipePositionKeys.LEFT
});

registerPageWipeOptions(routes.Press.path, routes.Explore.path, {
  startPos: wipePositionKeys.RIGHT
});

export default pageDirections;
