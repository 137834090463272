import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import styles from './HoverArea.module.scss';

import { cursorKeys } from '../../keys/cursor';

import { setCursorState, setIsMouseDown, setHoverAreaId } from '../../redux';

import { isTouchDevice } from '../../utils/detect';

function HoverArea({
  className,
  children,
  containerElement,
  cursorType,
  mouseOutCursorType,
  onHoldComplete,
  onMouseDown,
  onMouseUp
}) {
  const hoverId = useRef(Math.round(Math.random() * 100000));
  const dispatch = useDispatch();
  const { hoverAreaId, isHoldComplete } = useSelector((app) => app.cursor);
  const El = containerElement ? containerElement : 'div';
  const containerRef = useRef();

  const handleMouseEnter = useCallback(() => {
    dispatch(setCursorState(cursorType ? cursorType : cursorKeys.STATIC));
    dispatch(setHoverAreaId(hoverId.current));
  }, [dispatch, cursorType]);

  const handleMouseLeave = useCallback(() => {
    dispatch(setCursorState(mouseOutCursorType ? mouseOutCursorType : cursorKeys.STATIC));
    dispatch(setHoverAreaId(null));
  }, [dispatch, mouseOutCursorType]);

  const handleOnMouseDown = useCallback(() => {
    if (onMouseDown) onMouseDown();
    if (cursorType === cursorKeys.HOLD) {
      dispatch(setIsMouseDown(true));
    }
  }, [cursorType, dispatch, onMouseDown]);

  const handleOnMouseUp = useCallback(() => {
    if (onMouseUp) onMouseUp();
    dispatch(setIsMouseDown(false));
  }, [dispatch, onMouseUp]);

  useEffect(() => {
    // If this is the current item being hovered,
    // & The hold is complete,
    // & A callback is entered
    if (hoverAreaId === hoverId.current && isHoldComplete && onHoldComplete && cursorType === cursorKeys.HOLD) {
      onHoldComplete();
    }
  }, [isHoldComplete, onHoldComplete, hoverAreaId, cursorType]);

  /* =================================================
  Event Bindings
  ================================================= */

  //Event bindings for scroll track on both touch + desktop devices
  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('mousedown', handleOnMouseDown);
    document.addEventListener('mouseup', handleOnMouseUp);

    if (isTouchDevice) {
      container.addEventListener('touchstart', handleOnMouseDown);
      document.addEventListener('touchend', handleOnMouseUp);
    }

    //unsubscribe from events
    return () => {
      container.removeEventListener('mousedown', handleOnMouseDown);
      document.removeEventListener('mouseup', handleOnMouseUp);

      if (isTouchDevice) {
        container.removeEventListener('touchstart', handleOnMouseDown);
        document.removeEventListener('touchend', handleOnMouseUp);
      }
    };
  }, [handleOnMouseUp, handleOnMouseDown]);

  return (
    <El
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classnames(styles.HoverArea, className)}
    >
      {children}
    </El>
  );
}

HoverArea.propTypes = checkProps({
  className: PropTypes.string,
  containerElement: PropTypes.string,
  cursorType: PropTypes.string,
  mouseOutCursorType: PropTypes.string,
  onHoldComplete: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func
});

HoverArea.defaultProps = {
  cursorType: cursorKeys.FOCUS
};

export default memo(HoverArea);
