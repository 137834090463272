import copyPress from './copy-press';
import copyReviews from './copy-reviews';
import copyPasswordGate from './copy-passwordGate';
import copyPreloader from './copy-preloader';
import copyPersonas from './copy-personas';
import copyExperienceNav from './copy-experienceNav';
import copyCursor from './copy-cursor';
import copyProductDetails from './copy-product-details';
import copyHome from './copy-home';
import copyReel from './copy-reel';
import copyCookies from './copy-cookies';
import copyHotspots from './copy-hotspots';

export default {
  personas: copyPersonas,
  press: copyPress,
  productDetails: copyProductDetails,
  reviews: copyReviews,
  passwordGate: copyPasswordGate,
  preloader: copyPreloader,
  cursor: copyCursor,
  cookies: copyCookies,
  experienceNav: copyExperienceNav,
  home: copyHome,
  reel: copyReel,
  hotspots: copyHotspots
};
