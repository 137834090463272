import { Vector3 } from 'three';
import { gsap, Cubic, Elastic } from 'gsap';
import BaseScene from './base-scene';
import { clearFlags } from '../controllers/interaction-controller';
import { COLOR_SETS } from '../utils/constants';

export default class MainScene extends BaseScene {
  constructor(cameraRatio = 1, type) {
    super('MainScene', cameraRatio, type);

    this.canMoveCamera = true;
    this.targetCameraPosition = new Vector3();
    this.targetCameraPosition.z = 5;

    this.currentCameraPosition = new Vector3();
    this.currentCameraPosition.z = this.targetCameraPosition.z;

    this.direction = new Vector3();
    this.targetDirection = new Vector3();

    this.backgroundColor = 0x000000;
    this.elements = null;

    this.setCameraPosition(
      { x: this.targetCameraPosition.x, y: this.targetCameraPosition.y, z: this.targetCameraPosition.z },
      { x: 0, y: 0, z: 0 }
    );
  }

  setup = (props) => {
    const { config, element } = props;
    this.config = config;

    if (this.elements) {
      this.elements.dispose();
      this.elements = null;
      if (this.mesh) {
        this.scene.remove(this.mesh);
        this.mesh = null;
      }
    }

    const colorSet = COLOR_SETS[config.colorSet];

    this.elements = new element({ config, colorSet });

    this.elements.autoUpdateAnimation = false;
    this.mesh = this.elements.mesh;

    this.scene.add(this.mesh);
    this.targetCameraPosition.z = config.cameraSpeed || 5;

    this.setCameraFOV(config.cameraFOV || 65);
    this.setColorScheme(colorSet, 0.0, 0.0);

    this.initialAnimation = true;
    this.animateOut(0, 0, false);
  };

  animateIn = (duration = 2, delay = 0.0, reverse = true) => {
    return new Promise((resolve) => {
      if (this.elements) {
        if (reverse) {
          this.animateOut(0, 0, false);
        }

        if (this.initialAnimation) {
          delay += 0.667;
        }

        let currentValue = this.elements.getOpacity();
        currentValue.amount = this.elements.getAmount();
        let newValue = { x: 1.0, y: 2.0, z: 0.0, w: 0.0, amount: 0 };
        gsap.to(currentValue, {
          duration: duration * (this.initialAnimation ? 1.6 : 1),
          x: newValue.x,
          y: newValue.y,
          z: newValue.z,
          w: newValue.w,
          ease: 'power1.out',
          delay,
          onUpdate: () => {
            this.elements.updateOpacity(currentValue);
          }
        });
        gsap.to(currentValue, {
          duration: duration * (this.initialAnimation ? 2 : 1.2),
          amount: newValue.amount,
          ease: 'power3.out',
          delay,
          onUpdate: () => {
            this.elements.updateAmount(currentValue.amount);
          },
          onComplete: () => {
            resolve();

            this.initialAnimation = false;
          }
        });
      } else {
        resolve();
      }
    });
  };

  animateOut = (duration = 1.0, delay = 0.0, reverse = true) => {
    return new Promise((resolve) => {
      if (this.elements) {
        let currentValue = this.elements.getOpacity();
        currentValue.amount = this.elements.getAmount();
        let newValue = { x: 0.0, y: 1.0, z: 0.0, w: 0.0, amount: reverse ? 2 : -50 };
        gsap.to(currentValue, {
          duration,
          x: newValue.x,
          y: newValue.y,
          z: newValue.z,
          w: newValue.w,
          amount: newValue.amount,
          ease: 'power1.out',
          delay,
          onUpdate: () => {
            this.elements.updateOpacity(currentValue);
            this.elements.updateAmount(currentValue.amount);
          },
          onComplete: () => {
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  };

  animateVisualization = () => {
    if (this.elements) {
      gsap.to(this.elements, {
        duration: 1.0,
        visualizationIntensity: 1,
        visualizationSpread: 0.2,
        ease: 'sine.in',
        onComplete: () => {
          gsap.to(this.elements, {
            duration: 0.8,
            visualizationIntensity: 1,
            visualizationSpread: 0,
            ease: 'sine.out',
            delay: 4.2
          });
        }
      });
    }
  };

  animatePulse = (timeIn = 1.0, timeOut = 1.0, delay = 0.0) => {
    let currentValue = this.elements.getOpacity();
    currentValue.amount = this.elements.getAmount();
    gsap.to(currentValue, {
      duration: timeIn,
      x: 0.5,
      amount: 0.25,
      ease: 'sine.in',
      delay: delay,
      onUpdate: () => {
        this.elements.updateOpacity(currentValue);
        this.elements.updateAmount(currentValue.amount);
      },
      onComplete: () => {
        gsap.to(currentValue, {
          duration: timeOut,
          x: 1.0,
          amount: 0.0,
          ease: Elastic.easeOut.config(1.5, 1.4),
          onUpdate: () => {
            this.elements.updateOpacity(currentValue);
            this.elements.updateAmount(currentValue.amount);
          }
        });
      }
    });
  };

  animateIdle = (value = 0.0, time = 1.0, delay = 0.0) => {
    let currentTime = { value: this.elements.getIdle() };
    gsap.to(currentTime, {
      duration: time,
      value: value,
      ease: Cubic.easeInOut,
      delay: delay,
      onUpdate: () => {
        this.elements.updateIdle(currentTime.value);
      }
    });
  };

  setAnimationIntensity = (intensity = 0.5, colorIntensity = 0.5, time = 1.0, delay = 0.0) => {
    if (this.elements) {
      let currentIntensity = {
        intensity: this.elements.getPulseIntensity(),
        colorIntensity: this.elements.getPulseColorIntensity()
      };
      gsap.to(currentIntensity, {
        duration: time,
        intensity: intensity,
        colorIntensity: colorIntensity,
        ease: Cubic.easeInOut,
        delay: delay,
        onUpdate: () => {
          this.elements.updatePulseIntensity(currentIntensity.intensity);
          this.elements.updatePulseColorIntensity(currentIntensity.colorIntensity);
        }
      });
    }
  };

  animatePulseTime = (time = 1.0, delay = 0.0) => {
    let currentTime = { time: 0 };
    gsap.to(currentTime, {
      duration: time,
      time: 1.0,
      ease: Cubic.easeOut,
      delay: delay,
      onUpdate: () => {
        this.elements.updateMaterialPulseTime(currentTime.time);
      }
    });
  };

  updateTime = (time) => {
    this.elements.updateMaterialPulseTime(time);
  };

  startVisualization = () => {
    if (this.elements) {
      this.elements.startVisualization();
    }
  };

  stopVisualization = () => {
    if (this.elements) {
      this.elements.stopVisualization();
    }
  };

  setSound = (mute) => {
    if (this.elements) {
      this.elements.setSound(mute);
    }
  };

  setConfig = (config) => {
    if (this.elements) {
      this.elements.setConfig(config);
    }

    this.config = config;
  };

  reset = () => {
    this.targetDirection.x = 0;
    this.targetDirection.y = 0;
    this.targetDirection.z = 0;

    // this.targetCameraPosition.x = 0;
    // this.targetCameraPosition.y = 0;

    this.direction.lerp(this.targetDirection, 1.0);
    this.mousePosition.lerp(this.outMousePosition, 1.0);
    this.currentCameraPosition.lerp(this.targetCameraPosition, 1.0);

    if (this.elements) {
      this.elements.update(0.0, this.mousePosition, this.direction);
    }
  };

  update = (delta, controller, interactive, debug) => {
    if (controller && this.config) {
      const pointer = controller.getPointer(0);

      if (this.canMoveCamera) {
        if (interactive && pointer.onContainer && pointer.onScreen) {
          this.targetCameraPosition.x = pointer.center.x * this.config.cameraRotation.x;
          this.targetCameraPosition.y = pointer.center.y * this.config.cameraRotation.y;
        } else {
          this.targetCameraPosition.x = this.targetCameraPosition.y = 0;
        }

        this.targetCameraPosition.z = this.config.cameraZoom;
        this.currentCameraPosition.lerp(this.targetCameraPosition, this.config.cameraSpeed * delta);

        this.setCameraPosition(this.currentCameraPosition, {
          x: 0,
          y: 0,
          z: 0
        });
      }

      let pos = this.outMousePosition;
      let ease = 0.001;
      if (interactive && pointer.onScreen) {
        pos = this.getMouseWorldPosition(pointer.center.x, -pointer.center.y, 0.5);
        ease = 1;
      }
      this.mousePosition.lerp(pos, ease);

      if (interactive && pointer.onContainer && pointer.onScreen) {
        this.targetDirection.x += pointer.direction.x;
        this.targetDirection.y += -pointer.direction.y;
        this.targetDirection.z = Math.abs(this.targetDirection.x + this.targetDirection.y) * this.config.distortionZ;
      } else {
        pointer.direction.x = 0;
        pointer.direction.y = 0;
      }

      this.targetDirection.clampScalar(-1.0, 1.0);
      this.direction.lerp(this.targetDirection, 0.3);

      if (this.elements) {
        this.elements.update(delta, this.mousePosition, this.direction);
      }

      this.targetDirection.x *= this.config.distortionDumping;
      this.targetDirection.y *= this.config.distortionDumping;
      this.targetDirection.z *= this.config.distortionDumping;

      if (debug) {
        debug.updateMousePosition(pos);
      }

      controller.reset(this.config.distortionSmoothness, clearFlags.DIRECTION);
      return true;
    } else {
      return false;
    }
  };

  dispose = () => {
    if (this.elements) {
      this.elements.dispose();
    }
  };
}
