import { isTouchDevice } from '../../../../utils/detect';

export const clearFlags = {
  POSITION: 0,
  DIRECTION: 1,
  ALL: 2
};

let _active = false;
let _this = null;
let _pointers = [];
let _lastPosition = [];

/**
 * on Touch/MouseDown.
 * @param {Event} event
 */
function _onTouchStart(event) {
  InteractionController.isDown = true;
  _setPointers(event, InteractionController.isDown, true);
  _setPointersDownPosition(event);
}

/**
 * on TouchMove.
 * @param {Event} event
 */
function _onTouchMove(event) {
  _onMouseMove(event);
  if (!InteractionController.isDown) {
    return;
  }
  _setPointers(event, InteractionController.isDown, true);
}

/**
 * on TouchEnd.
 * @param {Event} event
 */
function _onTouchEnd(event) {
  InteractionController.isDown = false;
  _setPointers(event, InteractionController.isDown, false);
}

/**
 * on MouseMove.
 * @param {Event} event
 */
function _onMouseMove(event) {
  _setPointers(event, InteractionController.isDown, true);
}

/**
 * on MouseOut.
 * @param {Event} event
 */
function _onMouseOut(event) {
  // InteractionController.reset(0.0, clearFlags.ALL);
  _setPointers(event, InteractionController.isDown, false);
}

/**
 * on MouseIn.
 * @param {Event} event
 */
function _onMouseIn(event) {
  // InteractionController.reset(0.0, clearFlags.ALL);
  _setPointers(event, InteractionController.isDown, true);
}

/**
 * set pointer by index.
 * @param {Number} index
 * @param {Number} x
 * @param {Number} y
 */
function _setPointer(index, x, y, down, onScreen, onContainer) {
  _pointers[index].down = down;
  _pointers[index].onContainer = onContainer;
  _pointers[index].onScreen = onScreen;
  _pointers[index].x = x - InteractionController.offsetLeft;
  _pointers[index].y = y - InteractionController.offsetTop;
  _pointers[index].normal.x = _pointers[index].x / window.innerWidth;
  _pointers[index].normal.x = _pointers[index].normal.x > 1 ? 1 : _pointers[index].normal.x;
  _pointers[index].normal.x = _pointers[index].normal.x < 0 ? 0 : _pointers[index].normal.x;

  _pointers[index].normal.y = _pointers[index].y / window.innerHeight;
  _pointers[index].normal.y = _pointers[index].normal.y > 1 ? 1 : _pointers[index].normal.y;
  _pointers[index].normal.y = _pointers[index].normal.y < 0 ? 0 : _pointers[index].normal.y;

  _pointers[index].center.x = (_pointers[index].normal.x - 0.5) * 2.0;
  _pointers[index].center.x = _pointers[index].center.x > 1 ? 1 : _pointers[index].center.x;
  _pointers[index].center.x = _pointers[index].center.x < -1 ? -1 : _pointers[index].center.x;

  _pointers[index].center.y = (_pointers[index].normal.y - 0.5) * 2.0;
  _pointers[index].center.y = _pointers[index].center.y > 1 ? 1 : _pointers[index].center.y;
  _pointers[index].center.y = _pointers[index].center.y < -1 ? -1 : _pointers[index].center.y;

  _pointers[index].direction.x = _pointers[index].normal.x - _lastPosition[index].x;
  _pointers[index].direction.x = _pointers[index].direction.x > 1 ? 1 : _pointers[index].direction.x;
  _pointers[index].direction.x = _pointers[index].direction.x < -1 ? -1 : _pointers[index].direction.x;

  _pointers[index].direction.y = _pointers[index].normal.y - _lastPosition[index].y;
  _pointers[index].direction.y = _pointers[index].direction.y > 1 ? 1 : _pointers[index].direction.y;
  _pointers[index].direction.y = _pointers[index].direction.y < -1 ? -1 : _pointers[index].direction.y;

  _lastPosition[index].x = _pointers[index].normal.x;
  _lastPosition[index].y = _pointers[index].normal.y;
}

/**
 * set pointers.
 * @param {Event} event
 */
function _setPointers(event, down = false, onContainer = false) {
  if (event.touches) {
    let i = 0;
    Object.keys(event.touches).forEach((key) => {
      if (i < 10) {
        const pointer = event.touches[key];
        const x = pointer.pageX;
        const y = pointer.pageY;
        const onScreen = x < window.innerWidth && x > 0 && y < window.innerHeight && y > 0;
        _setPointer(i, x, y, down, onScreen, onContainer);
      }
      i++;
    });
  } else {
    let x = event.pageX;
    let y = event.pageY;
    const onScreen = x < window.innerWidth && x > 0 && y < window.innerHeight && y > 0;
    _setPointer(0, x, y, down, onScreen, onContainer);
  }
}

function _setPointersDownPosition(event, down = false) {
  if (event.touches) {
    let i = 0;
    Object.keys(event.touches).forEach((key) => {
      const pointer = event.touches[key];
      const x = pointer.pageX;
      const y = pointer.pageY;
      if (i < 10) {
        _pointers[i].downPos.x = x - InteractionController.offsetLeft;
        _pointers[i].downPos.y = y - InteractionController.offsetTop;
      }
      i++;
    });
  } else {
    let x = event.pageX;
    let y = event.pageY;
    _pointers[0].downPos.x = x - InteractionController.offsetLeft;
    _pointers[0].downPos.y = y - InteractionController.offsetTop;
  }
}

/** InteractionController */
export default class InteractionController {
  static resetPointers = () => {
    _pointers = [];
    _lastPosition = [];
    for (let i = 0; i < 10; i++) {
      _pointers.push({
        down: false,
        onScreen: false,
        onContainer: false,
        x: 0,
        y: 0,
        normal: { x: 0, y: 0 },
        center: { x: 0, y: 0 },
        direction: { x: 0, y: 0 },
        downPos: { x: 0, y: 0 }
      });
      _lastPosition.push({ x: 0, y: 0 });
    }
  };

  static setEvents = (isDesktop, destroy = false) => {
    _this.element.removeEventListener('touchend', _onTouchEnd);
    _this.element.removeEventListener('touchmove', _onTouchMove);
    _this.element.removeEventListener('touchstart', _onTouchStart);
    _this.element.removeEventListener('mouseup', _onTouchEnd);
    _this.element.removeEventListener('mousemove', _onTouchMove);
    _this.element.removeEventListener('mouseenter', _onMouseIn);
    _this.element.removeEventListener('mouseleave', _onMouseOut);
    _this.element.removeEventListener('mousedown', _onTouchStart);

    if (_this.element !== document) {
      document.removeEventListener('mouseenter', _onMouseIn);
      document.removeEventListener('mouseleave', _onMouseOut);
    }

    if (!destroy) {
      if (isDesktop) {
        _this.element.addEventListener('mouseleave', _onMouseOut);
        _this.element.addEventListener('mouseenter', _onMouseIn);

        if (_this.element !== document) {
          document.addEventListener('mouseenter', _onMouseIn);
          document.addEventListener('mouseleave', _onMouseOut);
        }
      } else {
        _this.element.addEventListener('touchend', _onTouchEnd);
        _this.element.addEventListener('touchmove', _onTouchMove);
        _this.element.addEventListener('touchstart', _onTouchStart);
      }

      _this.element.addEventListener('mouseup', _onTouchEnd);
      _this.element.addEventListener('mousemove', _onTouchMove);
      _this.element.addEventListener('mousedown', _onTouchStart);
    }
  };

  static start(parentElement) {
    if (!_active && parentElement) {
      _this = this;
      _this.element = parentElement;
      _this.isDown = false;
      _this.isDesktop = !isTouchDevice;
      _this.offsetLeft = 0;
      _this.offsetTop = 0;

      if (_this.element) {
        InteractionController.resetPointers();
        InteractionController.setEvents(_this.isDesktop);
        _active = true;
      }
    }
  }

  static stop = () => {
    InteractionController.setEvents(_this.isDesktop, true);
    _active = false;
  };

  static resize = () => {};

  static reset = (dumping = 0.0, flags = clearFlags.ALL) => {
    for (let i = 0; i < 10; i++) {
      if (flags === clearFlags.POSITION || flags === clearFlags.ALL) {
        _pointers[i].down = false;
        _pointers[i].onScreen = false;
        _pointers[i].onContainer = false;
        _pointers[i].x *= dumping;
        _pointers[i].y *= dumping;
        _pointers[i].normal.x *= dumping;
        _pointers[i].normal.y *= dumping;
        _pointers[i].center.x *= dumping;
        _pointers[i].center.y *= dumping;
        _pointers[i].downPos.x *= dumping;
        _pointers[i].downPos.y *= dumping;
      }

      if (flags === clearFlags.DIRECTION || flags === clearFlags.ALL) {
        _pointers[i].direction.x *= dumping;
        _pointers[i].direction.y *= dumping;
      }
    }
  };

  static getPointer(index) {
    if (index < _pointers.length) {
      return _pointers[index];
    } else {
      return {
        down: false,
        onScreen: false,
        onContainer: false,
        x: 0,
        y: 0,
        normal: { x: 0, y: 0 },
        center: { x: 0, y: 0 },
        direction: { x: 0, y: 0 },
        downPos: { x: 0, y: 0 }
      };
    }
  }
}
