import React, { memo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { gsap } from 'gsap';
import styles from './LoadingSpinner.module.scss';

const DURATION = 0.3;

function LoadingSpinner({ className, isActive, animationDuration }) {
  const ref = useRef();

  const animateIn = useCallback(() => {
    if (!ref?.current) return;

    gsap.to(ref.current, {
      autoAlpha: 1,
      duration: animationDuration ? animationDuration : DURATION
    });
  }, [ref, animationDuration]);

  const animateOut = useCallback(() => {
    if (!ref?.current) return;

    gsap.to(ref.current, {
      autoAlpha: 0,
      duration: animationDuration ? animationDuration : DURATION
    });
  }, [ref, animationDuration]);

  useEffect(() => {
    if (isActive) {
      animateIn();
    } else {
      animateOut();
    }
  }, [animateIn, animateOut, isActive]);

  return (
    <div ref={ref} className={classnames(styles.LoadingPulse, className)}>
      <div className={styles.ring1}></div>
      <div className={styles.ring2}></div>
    </div>
  );
}

LoadingSpinner.propTypes = checkProps({
  className: PropTypes.string,
  animationDuration: PropTypes.number,
  isActive: PropTypes.bool
});

LoadingSpinner.defaultProps = {};

export default memo(LoadingSpinner);
