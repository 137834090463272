import React, { memo, useCallback, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { gsap } from 'gsap';

import { setIsSoundOn } from '../../redux';
import useCopy from '../../utils/hooks/use-copy';

import PillButton from '../PillButton/PillButton';
import BaseButton from '../BaseButton/BaseButton';
import CookiesModal from '../CookiesModal/CookiesModal';
import EnterPillButton from '../EnterPillButton/EnterPillButton';
import HoverArea from '../HoverArea/HoverArea';

import styles from './PasswordGate.module.scss';

import pageGateKeys from '../../keys/pagegate';
import { cursorKeys } from '../../keys/cursor';

import apiRoutes from '../../data/api';
import { getSeenCookiesModal } from '../../utils/localStorage';
import { TRANSITION_TIME } from '../../data/constants';
import { easeOut1 } from '../../data/eases';

const ELEMENTS_ANIM_DURATION = 0.4;

const REF_KEYS = {
  SUBTITLE: 'SUBTITLE',
  TITLE: 'TITLE',
  BUTTON: 'BUTTON',
  MEDIA_KIT: 'MEDIA_KIT'
};

const PasswordGate = React.forwardRef(
  ({ className, onPasswordSuccess, onEnterExperienceClick, isActive, currentStep, handleSkipToMediaKitClick }, ref) => {
    const { passwordGate: COPY, home: COPY_HOME } = useCopy();
    const dispatch = useDispatch();
    const [showCookieModal, setShowCookieModal] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const [password, setPassword] = useState(null);
    const containerRef = useRef();
    const passwordContainerRef = useRef();
    const enterContainerRef = useRef();
    const focusContainer = useRef();
    const elRefs = useRef({});

    /* ====================================
    Main container animations
  ==================================== */
    const animateInit = useCallback(() => {
      if (!containerRef?.current) return;

      gsap.set(containerRef.current, {
        autoAlpha: 0
      });
    }, []);

    const animateIn = useCallback(() => {
      if (!containerRef?.current) return;

      gsap.to(containerRef.current, {
        display: 'flex', //requires this to show page behind
        autoAlpha: 1,
        duration: TRANSITION_TIME
      });
    }, []);

    const animateOut = useCallback(() => {
      if (!containerRef?.current) return;

      gsap.to(containerRef.current, {
        autoAlpha: 0,
        duration: TRANSITION_TIME,
        onComplete: () => {
          gsap.set(containerRef.current, { display: 'none' }); //requires this to show page behind
        }
      });
    }, []);

    /* ====================================
    Steps animations (password/enter)
  ==================================== */

    const elementsAnimInit = useCallback(() => {
      if (!passwordContainerRef?.current || !enterContainerRef?.current) return;

      gsap.set([passwordContainerRef.current, enterContainerRef.current], {
        autoAlpha: 0
      });
    }, []);

    const animateSteps = useCallback((step) => {
      const animateElementIn = (el) => {
        gsap.to(el, {
          autoAlpha: 1,
          duration: ELEMENTS_ANIM_DURATION
        });
      };

      const animateElementOut = (el, nextEl) => {
        gsap.to(el, {
          autoAlpha: 0,
          duration: ELEMENTS_ANIM_DURATION,
          onComplete: () => {
            animateElementIn(nextEl);
          }
        });
      };

      switch (step) {
        case pageGateKeys.PASSWORD:
          if (!passwordContainerRef?.current) return;
          animateElementOut(enterContainerRef.current, passwordContainerRef.current);
          break;
        case pageGateKeys.ENTER:
          if (!enterContainerRef?.current) return;
          animateElementOut(passwordContainerRef.current, enterContainerRef.current);
          break;
        default:
          break;
      }
    }, []);

    /* ====================================
    Enter Experience animation
    ==================================== */

    const enterAnimateInit = useCallback(() => {
      gsap.set(Object.values(elRefs.current), {
        autoAlpha: 0
      });

      gsap.set(elRefs.current[REF_KEYS.SUBTITLE], {
        y: 30
      });

      gsap.set(elRefs.current[REF_KEYS.TITLE], {
        y: '25%'
      });

      gsap.set(elRefs.current[REF_KEYS.BUTTON], {
        y: 40
      });

      gsap.set(elRefs.current[REF_KEYS.MEDIA_KIT], {
        y: 30
      });
    }, []);

    const enterAnimateIn = useCallback(() => {
      const subtitleDelay = 0;
      const titleDelay = 0.2;
      const buttonDelay = 0.5;
      const mediaKitDelay = 0.7;

      const tl = gsap.timeline();

      tl.set(
        elRefs.current[REF_KEYS.SUBTITLE],
        {
          autoAlpha: 1
        },
        subtitleDelay
      );

      tl.to(
        elRefs.current[REF_KEYS.SUBTITLE],
        {
          y: 0,
          duration: 0.67,
          ease: easeOut1
        },
        subtitleDelay
      );

      tl.to(
        elRefs.current[REF_KEYS.TITLE],
        {
          autoAlpha: 1,
          duration: 0.15
        },
        titleDelay
      );

      tl.to(
        elRefs.current[REF_KEYS.TITLE],
        {
          y: 0,
          duration: 0.87,
          ease: easeOut1
        },
        titleDelay
      );

      tl.to(
        elRefs.current[REF_KEYS.BUTTON],
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.67
        },
        buttonDelay
      );

      tl.to(
        elRefs.current[REF_KEYS.MEDIA_KIT],
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.67
        },
        mediaKitDelay
      );
    }, []);

    useImperativeHandle(ref, () => ({
      enterAnimateInit,
      enterAnimateIn
    }));

    /* ====================================
    UseEffects
    ==================================== */

    //Initialize default state of elements
    useEffect(() => {
      animateInit();
      elementsAnimInit();
    }, [elementsAnimInit, animateInit]);

    //Animate in current step (ie. password / enter experience)
    useEffect(() => {
      if (!currentStep) return;

      setTimeout(() => {
        animateSteps(currentStep);
      }, 50);
    }, [animateSteps, currentStep]);

    //Animate in/out whole container
    useEffect(() => {
      if (isActive) {
        animateIn();
      } else {
        animateOut();
      }
    }, [animateIn, animateOut, isActive]);

    //focus on element
    useEffect(() => {
      if (focusContainer.current !== null) {
        focusContainer.current.focus();
      }
    }, []);

    /* ====================================
    Handlers
    ==================================== */

    //Password submit handler
    const handleSubmit = useCallback(async () => {
      if (!password) return setError(COPY.error.enterValidPassword);

      setIsLoading(true);
      setError(null);

      const data = { password };

      const handleErr = (errorText) => {
        setIsLoading(false);
        setError(errorText);
      };

      try {
        const resp = await fetch(apiRoutes.EMBARGO, {
          method: 'POST',
          mode: 'same-origin',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (resp.status === 200) {
          setIsLoading(false);

          if (onPasswordSuccess) {
            onPasswordSuccess();
          }
        } else {
          handleErr(COPY.error.incorrectPassword);
        }
      } catch (err) {
        handleErr(COPY.error.unknownError);
      }
    }, [setError, password, setIsLoading, onPasswordSuccess, COPY.error]);

    useEffect(() => {
      const seenCookiesModal = getSeenCookiesModal();
      const init = async () => {
        await fetch(apiRoutes.COOKIE).then((res) => {
          res
            .json()
            .then((data) => {
              if (res.status === 200 && data.showCookieBanner && !seenCookiesModal) {
                setShowCookieModal(true);
              }
            })
            .catch((err) => {
              console.error(err, 'Error with cookie banner endpoint');
            });
        });
      };

      init();
    }, []);

    //Handle enter experience click
    const handleEnterExperience = useCallback(() => {
      if (onEnterExperienceClick) {
        // Start sound when we enter the experience.
        dispatch(setIsSoundOn(true));
        onEnterExperienceClick();
      }
    }, [dispatch, onEnterExperienceClick]);

    return (
      <div className={classnames(styles.PasswordGate, className)} ref={containerRef}>
        {showCookieModal && <CookiesModal />}
        <div className={styles.headerContainer}>
          <div tabIndex="0" ref={focusContainer} className={styles.focusContent}>
            <p
              ref={(ref) => {
                elRefs.current[REF_KEYS.SUBTITLE] = ref;
              }}
              className={styles.subtitle}
            >
              <span>{currentStep === pageGateKeys.ENTER ? COPY_HOME.eyebrow : COPY.introducing}</span>
            </p>
            <h1
              ref={(ref) => {
                elRefs.current[REF_KEYS.TITLE] = ref;
              }}
              className={styles.title}
            >
              <span>{currentStep === pageGateKeys.ENTER ? COPY_HOME.title : COPY.title}</span>
            </h1>
          </div>
          <div className={styles.enterExperienceContainer} ref={enterContainerRef}>
            <div
              ref={(ref) => {
                elRefs.current[REF_KEYS.BUTTON] = ref;
              }}
            >
              <EnterPillButton
                className={styles.button}
                onButtonClick={handleEnterExperience}
                isActive
                text={currentStep === pageGateKeys.ENTER ? COPY_HOME.pillText : COPY.enterExperience}
              />
            </div>
            <HoverArea cursorType={cursorKeys.FOCUS} className={styles.skipToKit}>
              <BaseButton
                ref={(ref) => {
                  elRefs.current[REF_KEYS.MEDIA_KIT] = ref;
                }}
                tabIndex="0"
                onClick={handleSkipToMediaKitClick}
              >
                {COPY.skip}
              </BaseButton>
            </HoverArea>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className={styles.passwordContainer}
            ref={passwordContainerRef}
          >
            <div className={styles.passwordContainerInner}>
              {error && <div className={styles.error}>{error}</div>}
              <input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className={styles.passwordInput}
                type="password"
                name="password"
                id="pass"
              />
              <label className={styles.passwordInputLabel} htmlFor="pass">
                {COPY.password}
              </label>
              <div tabIndex="0" className={styles.buttonContainer}>
                <HoverArea cursorType={cursorKeys.FOCUS}>
                  <PillButton
                    className={styles.button}
                    onButtonClick={handleSubmit}
                    isActive={true}
                    text={loading ? COPY.submitting : COPY.enter}
                    disabled={loading}
                  />
                </HoverArea>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
);

PasswordGate.propTypes = checkProps({
  className: PropTypes.string,
  onPasswordSuccess: PropTypes.func,
  onEnterExperienceClick: PropTypes.func,
  isActive: PropTypes.bool,
  currentStep: PropTypes.string,
  handleSkipToMediaKitClick: PropTypes.func
});

PasswordGate.defaultProps = {};

export default memo(PasswordGate);
