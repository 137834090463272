import gsap from 'gsap';
import wait from '@jam3/wait';
import { Vector3 } from 'three';

import standardVert from '../../../glsl/standard.vert';
import standardFrag from '../../../glsl/standard.frag';

const V3 = new Vector3();

export default class ProductElements {
  constructor(props, visibility, type) {
    this.props = props;
    this.type = type;

    this.uniforms = {
      u_progress: { value: 0.0 },
      u_reverse: { value: false }
    };

    this.init(visibility);
  }

  init(visibility) {
    const { geometry, config } = this.props;
    this.mesh = geometry;
    this.mesh.renderOrder = 2;

    this.mesh.position.fromArray(config.position);
    this.mesh.rotation.fromArray(config.rotation);

    this.setVisibility(visibility);
  }

  materialConfig(textures) {
    const { config } = this.props;
    this.materials = [];
    this.mesh.traverse((child) => {
      if (child.isMesh) {
        config.materialConfig.forEach((el) => {
          if ((el.key === '*' || el.key === child.material.name) && !child.material.userData?.isConfig) {
            child.material.userData = {
              ...child.material.userData,
              isConfig: true
            };

            if (el.textureConfig) {
              Object.assign(child.material.map, el.textureConfig);
            }

            child.material.envMap = textures[el.envMap].texture;

            Object.assign(child.material, el.config);

            this.configureMaterial(child.material);

            child.material.needsUpdate = true;
            this.materials.push(child.material);
          }
        });
      }
    });

    config.materialOverride?.forEach((el) => {
      let mesh = this.mesh.getObjectByName(el.key);
      if (!mesh) return;

      if (!mesh.isMesh) {
        mesh.traverse((child) => {
          if (child.isMesh) {
            mesh = child;
          }
        });
      }

      if (el.config && Object.keys(el.config)) {
        const material = mesh.material.clone();

        Object.assign(material, el.config);
        material.needsUpdate = true;
        material.name = el.key;

        this.configureMaterial(material);

        mesh.material = material;
        this.materials.push(mesh.material);
      }

      if (el.scale) {
        mesh.scale.multiply(V3.fromArray(el.scale));
      }

      if (el.offset) {
        mesh.position.add(V3.fromArray(el.offset));
      }
    });
  }

  configureMaterial(material) {
    material.onBeforeCompile = (shader) => {
      Object.assign(shader.uniforms, this.uniforms);
      shader.vertexShader = standardVert;
      shader.fragmentShader = standardFrag;
    };
  }

  // Temporary animation
  setVisibility(visible = false) {
    this.mesh.visible = visible;
  }

  showElement(duration = 0.66, delay = 0, wipe = false) {
    return new Promise((resolve) => {
      this.setVisibility(true);
      this.uniforms.u_reverse.value = true;
      if (wipe) {
        this.mesh.renderOrder = 1;
        gsap.to(this.uniforms.u_progress, { value: 1, ease: 'power4.inout', duration: duration / 2, delay });
        this.materials.forEach((el) => {
          el.opacity = 1;
        });
      } else {
        this.uniforms.u_progress.value = 1;
        this.materials.forEach((el) => {
          gsap.to(el, { opacity: 1, duration, delay });
        });
      }

      wait((duration + delay) * 1000).then(() => {
        resolve();
      });
    });
  }

  hideElement(duration = 0.66, delay = 0, wipe = false) {
    return new Promise((resolve) => {
      this.uniforms.u_reverse.value = false;
      if (wipe) {
        this.mesh.renderOrder = 2;
        gsap.to(this.uniforms.u_progress, { value: 0, ease: 'power4.inout', duration: duration / 2, delay });
      } else {
        this.materials.forEach((el) => {
          gsap.to(el, { opacity: 0, duration, delay });
        });
      }

      wait((duration + delay) * 1000).then(() => {
        this.setVisibility(false);
        if (!wipe) {
          this.uniforms.u_progress.value = 0;
        }
        resolve();
      });
    });
  }

  update(delta) {}

  dispose() {
    this.mesh.traverse((child) => {
      if (child.isMesh) {
        child.geometry?.dispose();
        child.material?.dispose();
      }
    });
  }
}
