import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { gsap } from 'gsap';

import styles from './Preloader.module.scss';

import SvgSonosLogo from '../../assets/svgs/svg-sonos-logo.svg';
import SvgSound from '../../assets/svgs/svg-sound.svg';

import useCopy from '../../utils/hooks/use-copy';

import Rings from '../Rings/Rings';

import { preloaderRingEase, preloaderLogoEase } from '../../data/eases';

export const CIRCLE_ANIMATION_DURATION = 0.6;
export const ANIMATE_IN_OUT_DURATION = 0.3;
const CIRCLE_DIAMETER = 180;
const CIRCLE_STROKE_WIDTH = 0.5;
const CIRCLE_STROKE_DASH_ARR = 2 * Math.PI * (CIRCLE_DIAMETER / 2);
const CIRCLE_STYLES = styles.circleLine;

function Preloader({ className, isActive, percentLoaded, onLoadComplete }) {
  const { preloader: COPY } = useCopy();
  const loadContainerRef = useRef();
  const containerRef = useRef();
  const hasFiredLoadComplete = useRef(false);
  const svgContainerRef = useRef();
  const logoRefContainer = useRef();
  const soundIconRef = useRef();
  const soundDisclaimerRef = useRef();
  const ringsRef = useRef();

  //Initialize default anim state of component
  const animateInInit = useCallback(() => {
    gsap.set(containerRef.current, {
      autoAlpha: 0
    });

    gsap.set(logoRefContainer.current, {
      autoAlpha: 0,
      scale: 1.1
    });

    gsap.set([soundIconRef.current, soundDisclaimerRef.current], {
      autoAlpha: 0,
      y: 20
    });
  }, []);

  //Animate in the entire component
  const animateIn = useCallback(() => {
    if (!containerRef?.current) return;

    const tl = gsap.timeline();

    tl.to(containerRef.current, {
      autoAlpha: 1,
      duration: ANIMATE_IN_OUT_DURATION
    });

    tl.to(
      logoRefContainer.current,
      {
        scale: 1,
        ease: preloaderLogoEase,
        autoAlpha: 1,
        duration: 0.6
      },
      0
    );

    tl.to(
      svgContainerRef.current,
      {
        rotation: '360',
        duration: 2.8,
        ease: 'none',
        repeat: -1
      },
      0
    );

    tl.to(
      [soundIconRef.current, soundDisclaimerRef.current],
      {
        autoAlpha: 1,
        y: 0,
        stagger: 0.15
      },
      0.3
    );
  }, []);

  //Animation Out
  const animateOut = useCallback(() => {
    const tl = gsap.timeline();

    const duration = 0.7;

    const generalFadeOut = {
      ease: preloaderLogoEase,
      autoAlpha: 0,
      duration: duration
    };

    //Fade out logo
    tl.to(logoRefContainer.current, generalFadeOut, 0.2);

    //Fade out sound disclaimer + icon
    tl.to([soundIconRef.current, soundDisclaimerRef.current], generalFadeOut, 0);

    //Enlarge and fade out circle
    tl.to(
      svgContainerRef.current,
      {
        scale: 2,
        ease: preloaderRingEase,
        autoAlpha: 0,
        duration: duration
      },
      0
    );

    tl.to(
      svgContainerRef.current,
      {
        ease: preloaderRingEase,
        autoAlpha: 0,
        duration: 0.2
      },
      '-=0.2'
    );

    const animateRingsOut = () => {
      ringsRef.current?.animateOut();
    };

    tl.add(animateRingsOut, 0.2);
  }, []);

  //Animate out the entire component
  const animateOutContainer = useCallback(() => {
    if (!containerRef?.current) return;

    gsap.to(containerRef.current, {
      autoAlpha: 0,
      duration: ANIMATE_IN_OUT_DURATION
    });
  }, []);

  //Line animation around circle
  const animateCircleStroke = useCallback((percentLoaded) => {
    if (!loadContainerRef?.current || !percentLoaded) return;

    const line = loadContainerRef.current.querySelector(`.${CIRCLE_STYLES}`);
    if (!line) return;

    //Get the percent complete
    const dashOffset = (1 - percentLoaded / 100) * CIRCLE_STROKE_DASH_ARR;

    //Animate line
    return gsap.to(line, {
      duration: CIRCLE_ANIMATION_DURATION,
      attr: {
        'stroke-dashoffset': dashOffset
      }
    });
  }, []);

  useEffect(() => {
    animateInInit();
  }, [animateInInit]);

  useEffect(() => {
    if (isActive) {
      animateIn();
    } else {
      animateOutContainer();
    }
  }, [isActive, animateIn, animateOutContainer]);

  useEffect(() => {
    animateCircleStroke(percentLoaded);
  }, [percentLoaded, animateCircleStroke]);

  useEffect(() => {
    if (percentLoaded >= 100 && !hasFiredLoadComplete.current) {
      hasFiredLoadComplete.current = true;
      setTimeout(() => {
        if (onLoadComplete) onLoadComplete();
        animateOut();
      }, CIRCLE_ANIMATION_DURATION * 1000);
    }
  }, [percentLoaded, animateOut, onLoadComplete]);

  return (
    <div className={classnames(styles.Preloader, className)} ref={containerRef}>
      <div className={styles.loadContainer} ref={loadContainerRef}>
        <div ref={svgContainerRef}>
          <svg
            className={styles.preloadSvg}
            width={CIRCLE_DIAMETER}
            height={CIRCLE_DIAMETER}
            viewBox={`0 0 ${CIRCLE_DIAMETER} ${CIRCLE_DIAMETER}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx={CIRCLE_DIAMETER / 2}
              cy={CIRCLE_DIAMETER / 2}
              r={(CIRCLE_DIAMETER / 2) * 0.95}
              strokeWidth={CIRCLE_STROKE_WIDTH}
              className={CIRCLE_STYLES}
              strokeDasharray={CIRCLE_DIAMETER * Math.PI}
              strokeDashoffset={CIRCLE_STROKE_DASH_ARR}
            />
          </svg>
        </div>
        <div ref={logoRefContainer} className={styles.logoContainer}>
          <SvgSonosLogo className={styles.logo} />
        </div>
        <Rings ref={ringsRef} className={styles.rings} />
      </div>
      <div className={styles.soundDisclaimer}>
        <div ref={soundIconRef}>
          <SvgSound />
        </div>
        <p ref={soundDisclaimerRef} className={styles.soundDisclaimerText}>
          {COPY.turnOnSound}
        </p>
      </div>
    </div>
  );
}

Preloader.propTypes = checkProps({
  className: PropTypes.string,
  percentLoaded: PropTypes.number,
  isActive: PropTypes.bool,
  onLoadComplete: PropTypes.func
});

Preloader.defaultProps = {};

export default memo(Preloader);
