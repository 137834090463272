import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userIsValid: process.env.NEXT_PUBLIC_PAGEGATE_DISABLED === 'true'
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserIsValid(state, action) {
      return { ...state, userIsValid: action.payload };
    }
  }
});
