import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { setWipeRef } from '../../redux';

import styles from './WipeElement.module.scss';

const WIPE_ID = 'wipe';

function WipeElement({ className }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWipeRef(WIPE_ID));
  }, [dispatch]);

  return <div id={WIPE_ID} className={classnames(styles.WipeElement, className)} />;
}

WipeElement.propTypes = checkProps({
  className: PropTypes.string
});

WipeElement.defaultProps = {};

export default memo(WipeElement);
