import {
  AmbientLight,
  BufferGeometry,
  EllipseCurve,
  Group,
  Line,
  LineDashedMaterial,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  SpotLight,
  Vector2,
  Vector3
} from 'three';
import { gsap } from 'gsap';

import BaseScene from './base-scene';
import {
  COLOR_SETS,
  DEFAULT_PRODUCT,
  HOTSPOTS,
  PRODUCT_TYPES,
  Debug,
  INITIAL_PRODUCT_ROTATION,
  PRODUCT_CAMERA_DAMPING,
  PRODUCT_CAMERA_ORBIT,
  MODEL_CONFIG,
  PRODUCT_CAMERA_ZOOM
} from '../utils/constants';
import { isTouchDevice } from '../../../../utils/detect';
import { cursorKeys } from '../../../../keys/cursor';
import { clamp, worldToScreen } from '../utils/utils';

export default class ProductScene extends BaseScene {
  constructor(cameraRatio = 1, type) {
    super('ProductScene', cameraRatio, type);

    this.cameraRotationTarget = {
      down: INITIAL_PRODUCT_ROTATION,
      y: INITIAL_PRODUCT_ROTATION,
      current: INITIAL_PRODUCT_ROTATION,
      damping: PRODUCT_CAMERA_DAMPING
    };
    this.cameraPan = {
      current: 0,
      target: 0,
      down: 0,
      bound: {
        min: -5,
        max: 2
      },
      damping: PRODUCT_CAMERA_DAMPING
    };
    this.cameraPositionTarget = new Vector3();
    this.cameraInitialPosition = new Vector3();
    this.cameraOffset = new Vector3();
    this.orbitRadius = PRODUCT_CAMERA_ORBIT;

    this.elements = {};
    this.product = DEFAULT_PRODUCT;

    this.hotspots = [];
    this.hotspotsContainer = new Group();
    this.hotspotPosition = new Vector3();
    this.showHotspot = false;

    this.intersects = [];

    this.needsUpdate = false;

    this.mouse = {
      down: false,
      x: 0,
      y: 0,
      screen: new Vector2(),
      downPos: { x: 0, y: 0 }
    };

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);

    this.autoRotate = true;
  }

  setup = (props) => {
    const { config } = props;
    this.props = props;
    this.config = config;

    this.cameraInitialPosition.fromArray(this.config.cameraPosition);
    this.cameraOffset.fromArray(this.config.cameraPositionOffset);

    this.camera.zoom = PRODUCT_CAMERA_ZOOM;

    const colorSet = COLOR_SETS[config.colorSet];
    this.setColorScheme(colorSet, 0.0, 0.0);

    this.lights = [];
    this.objects = [];

    this.ambientLight = new AmbientLight(0xffffff, 1.2);
    this.lights.push(this.ambientLight);

    const spotLight = new SpotLight(0xffffff, 1, 500, 1, 1, 2);
    spotLight.position.set(180, -33, 250);
    this.lights.push(spotLight);

    // const spotLight2 = new SpotLight(0xffffff, 0.2, 500, 1, 1, 2);
    // spotLight2.position.set(110, -50, -200);
    // this.lights.push(spotLight2);

    this.scene.add(spotLight);
    // this.scene.add(spotLight2);
    this.scene.add(this.ambientLight);

    const curve = new EllipseCurve(
      0,
      0, // ax, aY
      20,
      20, // xRadius, yRadius
      0,
      2 * Math.PI, // aStartAngle, aEndAngle
      false, // aClockwise
      0 // aRotation
    );
    const points = curve.getPoints(64);
    const circleGeometry = new BufferGeometry().setFromPoints(points);
    const dashMaterial = new LineDashedMaterial({
      color: 0xfebca5,
      linewidth: 2,
      scale: 30,
      dashSize: 10,
      gapSize: 5,
      transparent: true,
      opacity: 0
    });
    this.positionMesh = new Line(circleGeometry, dashMaterial);
    this.positionMesh.computeLineDistances();
    this.positionMesh.rotation.x = Math.PI / -2;
    this.objects.push(this.positionMesh);
    this.scene.add(this.positionMesh);

    const sphereGeometry = new SphereGeometry(1, 16, 16);
    const basicMaterial = new MeshBasicMaterial({ color: 0xfebca5, transparent: true, opacity: 0 });
    this.frontIndicator = new Mesh(sphereGeometry, basicMaterial);
    this.frontIndicator.scale.setScalar(0.4);
    this.frontIndicator.position.z = 20;
    this.objects.push(this.frontIndicator);
    this.scene.add(this.frontIndicator);

    // Shadow
    // const planeGeometry = new PlaneGeometry(1, 1, 1, 1);
    // const shadowMaterial = new ShadowMaterial();
    // this.shadowPlane = new Mesh(planeGeometry, shadowMaterial);
    // this.shadowPlane.rotation.x = Math.PI / -2;
    // this.shadowPlane.rotation.z = Math.PI / 4;
    // this.shadowPlane.position.y = -0.01;
    // this.shadowPlane.scale.setScalar(80);
    // this.shadowPlane.receiveShadow = true;
    // this.objects.push(this.shadowPlane);
    // this.scene.add(this.shadowPlane);
  };

  processModels = (assets, visibility) => {
    const {
      element,
      config: { modelId }
    } = this.props;
    this.assets = assets;

    MODEL_CONFIG.forEach((el) => {
      const model = new element(
        {
          geometry: this.assets.models[modelId].geometry.getObjectByName(el.id),
          config: el,
          light: this.ambientLight
        },
        el.id === this.product,
        el.id
      );
      model.materialConfig(this.assets.textures);
      this.elements[el.id] = model;
      this.scene.add(model.mesh);
    });

    HOTSPOTS.forEach((el) => {
      const hotspot = new el.element({
        ...el,
        texture: this.assets.textures[el.texture],
        ringTexture: this.assets.textures[el.ring]
      });

      this.hotspots.push(hotspot);
      this.hotspotsContainer.add(hotspot.mesh);
    });

    this.scene.add(this.hotspotsContainer);

    this.activeElement = this.elements[this.product];

    this.addListeners();

    visibility && this.animateIn();
  };

  handleMouseDown = (event) => {
    if (!this.product || this.product === PRODUCT_TYPES.EMPTY || this.currentHotspot || !this.active) return;
    this.autoRotate = false;
    this.mouse.down = true;

    this.updateMouse(event);

    this.mouse.downPos = {
      x: this.mouse.x,
      y: this.mouse.y
    };

    this.cameraRotationTarget.down = this.cameraRotationTarget.current;
    this.cameraPan.down = this.cameraPan.current;

    if (!this.hoverElement) {
      this.hideHotspots(0.33);
    }

    if (this.cursorState === cursorKeys._360 && this.showHotspot) {
      const { setCursorHoverState } = this.props;
      setCursorHoverState(cursorKeys._360_HOLD);
    }
  };

  handleMouseMove = (event) => {
    if (!this.product || this.product === PRODUCT_TYPES.EMPTY || this.currentHotspot || !this.active) return;

    this.updateMouse(event);
    this.checkIntersections();

    this.mouse.screen.x = (this.mouse.x / window.innerWidth) * 2 - 1;
    this.mouse.screen.y = -(this.mouse.y / window.innerHeight) * 2 + 1;
  };

  handleMouseUp = (event) => {
    if (!this.product || this.product === PRODUCT_TYPES.EMPTY || this.currentHotspot) return;
    this.mouse.down = false;

    if (this.showHotspot) {
      this.showHotspots(0.33);
    }

    const { setCursorHoverState } = this.props;

    // Additional check if user moved cursor after opening a hotspot
    this.updateMouse(event);

    this.mouse.screen.x = (this.mouse.x / window.innerWidth) * 2 - 1;
    this.mouse.screen.y = -(this.mouse.y / window.innerHeight) * 2 + 1;

    this.checkIntersections();
    this.openHotspot();

    if (this.cursorState === cursorKeys._360_HOLD && this.showHotspot) {
      setCursorHoverState(cursorKeys._360);
    }
  };

  updateMouse = (event) => {
    if (event.changedTouches?.length) {
      this.mouse.x = event.changedTouches[0].pageX;
      this.mouse.y = event.changedTouches[0].pageY;
    } else {
      this.mouse.x = event.clientX;
      this.mouse.y = event.clientY;
    }
  };

  handleMouseState = (state) => {
    this.cursorState = state;
  };

  animateIn = (duration = 1.0, delay = 0.0, wipe = false) => {
    return new Promise((resolve) => {
      if (this.product === PRODUCT_TYPES.EMPTY) {
        resolve();
      } else {
        const TL = gsap.timeline({
          onComplete: () => {
            resolve();
          }
        });
        this.activeElement = this.elements[this.product];

        if (this.showHotspot) {
          TL.add(() => {
            this.showHotspots(0.667, delay);
          }, 0.333);
        }

        TL.add(() => {
          this.activeElement?.showElement(0.667, delay, wipe);
        }, 0);

        TL.to(this.positionMesh.material, { opacity: 1, duration: 0.667, delay }, 0.333);
        TL.to(this.frontIndicator.material, { opacity: 1, duration: 0.667, delay }, 0.333);
        TL.to(this.cameraOffset, { x: 0, y: 0, z: 0, ease: 'power2.out', duration, delay }, 0);
      }
    });
  };

  animateOut = (duration = 1.0, delay = 0.0, wipe = false) => {
    return new Promise((resolve) => {
      const TL = gsap.timeline({
        onComplete: () => {
          resolve();
        }
      });

      TL.add(() => {
        this.activeElement?.hideElement(0.667, delay, wipe).then(() => {
          this.activeElement = null;
        });
      }, 0.333);

      TL.add(() => {
        this.hideHotspots(0.667, delay, true);
      }, 0);
      TL.to(this.positionMesh.material, { opacity: 0, duration: 0.667, delay }, 0);
      TL.to(this.frontIndicator.material, { opacity: 0, duration: 0.667, delay }, 0);
      TL.to(
        this.cameraOffset,
        {
          x: this.config.cameraPositionOffset[0],
          y: this.config.cameraPositionOffset[1],
          z: this.config.cameraPositionOffset[2],
          ease: 'power1.inout',
          duration,
          delay
        },
        0
      );
    });
  };

  setProduct = (product) => {
    return new Promise((resolve) => {
      if (this.product === product) return;
      const previous = this.product;
      this.product = product;

      if (Object.keys(this.elements).length) {
        if (product === PRODUCT_TYPES.EMPTY) {
          this.animateOut().then(() => {
            this.needsUpdate = false;
            resolve();
          });
        } else {
          this.needsUpdate = true;
          const wipe = previous !== PRODUCT_TYPES.EMPTY;
          if (this.activeElement && this.product !== this.activeElement?.type) {
            this.activeElement.hideElement(0.667, 0, wipe);
          }
          this.animateIn(0.667, 0, wipe).then(() => {
            resolve();
          });
        }
      }
    });
  };

  setHotspot = (id) => {
    this.currentHotspot = id;

    if (!id) {
      this.hotspots.forEach((el) => {
        el.close();
      });
    }
  };

  setShowHotspot = (visible) => {
    this.showHotspot = visible;

    if (this.showHotspot) {
      this.showHotspots();
    } else {
      this.hideHotspots(0.667, 0, true);
    }
  };

  showHotspots = (duration = 0.667, delay = 0) => {
    this.hotspots.forEach((el, index) => {
      el.show(duration, 'power1.out', delay + index * 0.066);
    });
  };

  hideHotspots = (duration = 0.667, delay = 0, force = false) => {
    this.hotspots.forEach((el, index) => {
      el.hide(duration, 'power1.in', delay + index * 0.033, force);
    });
  };

  openHotspot = () => {
    if (this.hoverElement) {
      const { setProductHotspot, setCursorHoverState } = this.props;

      this.hoverElement.sprite.getWorldPosition(this.hotspotPosition);
      this.hoverElement.open();

      const { x, y } = worldToScreen(this.hotspotPosition, this.camera, window.innerWidth, window.innerHeight);

      Debug.log(`[WebGL] Dispatching hotspot: x: ${x}, y: ${y}, id: ${this.hoverElement.id}`);

      setCursorHoverState(cursorKeys.STATIC);
      setProductHotspot({
        x,
        y,
        id: this.hoverElement.id
      });

      if (isTouchDevice) {
        document.body.style.cursor = 'auto';
      }
    }
  };

  checkIntersections = () => {
    const { raycaster, setCursorHoverState } = this.props;

    raycaster.setFromCamera(this.mouse.screen, this.camera);
    this.intersects = raycaster.intersectObjects(this.hotspotsContainer.children, true);

    if (this.intersects.length) {
      setCursorHoverState(cursorKeys.FOCUS);
      const hoverElement = this.intersects[0].object?.userData?.el;

      if (this.hoverElement?.id !== hoverElement?.id) {
        this.hoverElement?.handleMouseLeave();
      }

      this.hoverElement = hoverElement;
      !this.hoverElement?.isHover && this.hoverElement?.handleMouseEnter();

      if (isTouchDevice) {
        document.body.style.cursor = 'pointer';
      }
    } else {
      if (this.hoverElement) {
        setCursorHoverState(cursorKeys._360);
        this.hoverElement?.handleMouseLeave();
        this.hoverElement = null;
      }

      if (this.cursorState === cursorKeys.STATIC && this.showHotspot) {
        setCursorHoverState(cursorKeys._360);
      }

      if (isTouchDevice) {
        document.body.style.cursor = 'auto';
      }
    }
  };

  addListeners = () => {
    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);

    // windows surface
    if (isTouchDevice) {
      document.addEventListener('touchstart', this.handleMouseDown);
      document.addEventListener('touchmove', this.handleMouseMove);
      document.addEventListener('touchend', this.handleMouseUp);
    }
  };

  removeListeners = () => {
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);

    if (isTouchDevice) {
      document.removeEventListener('touchstart', this.handleMouseDown);
      document.removeEventListener('touchmove', this.handleMouseMove);
      document.removeEventListener('touchend', this.handleMouseUp);
    }
  };

  update = (delta, controller) => {
    if (this.product === PRODUCT_TYPES.EMPTY && !this.needsUpdate) return false;

    if (controller && this.config && !this.currentHotspot) {
      const {
        down: cursorDown,
        x,
        y,
        downPos: { x: downPosX, y: downPosY }
      } = this.mouse;

      if (cursorDown && !this.autoRotate) {
        this.cameraPan.target = this.cameraPan.down + (downPosY - y) * 0.01;
        this.cameraRotationTarget.y = this.cameraRotationTarget.down + (downPosX - x) * 0.003;
      }
    }

    if (this.autoRotate) {
      this.cameraRotationTarget.y -= delta * 0.1;
    }

    this.hotspots.forEach((el) => {
      el.update(delta);
    });

    Object.keys(this.elements).forEach((key) => {
      this.elements[key].update(delta);
    });

    this.cameraRotationTarget.current +=
      (this.cameraRotationTarget.y - this.cameraRotationTarget.current) * this.cameraRotationTarget.damping;

    this.cameraPan.current += (this.cameraPan.target - this.cameraPan.current) * this.cameraRotationTarget.damping;
    this.cameraPan.current = clamp(this.cameraPan.current, this.cameraPan.bound.min, this.cameraPan.bound.max);

    this.cameraPositionTarget.x =
      Math.sin(this.cameraRotationTarget.current) * (this.orbitRadius - this.cameraPan.current / 2);
    this.cameraPositionTarget.z =
      Math.cos(this.cameraRotationTarget.current) * (this.orbitRadius - this.cameraPan.current / 2);
    this.cameraPositionTarget.y = this.cameraPan.current + this.cameraInitialPosition.y;
    this.cameraPositionTarget.add(this.cameraOffset);

    this.camera.position.copy(this.cameraPositionTarget);

    this.camera.lookAt(0, 0, 0);

    return true;
  };

  dispose = () => {
    Object.keys(this.elements).forEach((key) => {
      this.elements[key].dispose();
    });

    this.objects?.forEach((el) => {
      el.geometry?.dispose();
      el.material?.dispose();
    });
    this.objects = [];

    this.lights?.forEach((el) => {
      el.dispose();
    });
    this.lights = [];

    this.hotspots.forEach((el) => {
      el.dispose();
    });
    this.hotspots = [];
    this.hotspotsContainer = null;
    this.currentHotspot = null;
    this.showHotspot = null;

    this.needsUpdate = null;

    this.intersects = [];

    this.removeListeners();
  };
}
