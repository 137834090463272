import React, { memo, useEffect, useRef, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { useRouter } from 'next/router';
import { gsap } from 'gsap';
import { useWindowSize } from '@jam3/react-hooks';

import styles from './Cursor.module.scss';

import Caret from '../../assets/svgs/svg-caret.svg';

import { cursorKeys, holdKeys } from '../../keys/cursor';

import { setIsHoldComplete } from '../../redux';

import routes from '../../data/routes';

import useCopy from '../../utils/hooks/use-copy';
import detect, { isTouchDevice } from '../../utils/detect';
import { lerp } from '../../utils/basic-functions';
import { cursorEase } from '../../data/eases';
import { resizeDebounceTime } from '../../data/settings';

const HIDE_CURSOR_CLASS = styles.hideCursor;

const CIRCLE_DIAMETER = 39;
const CIRCLE_STROKE_WIDTH = 1;
const CIRCLE_STROKE_DASH_ARR = 2 * Math.PI * (CIRCLE_DIAMETER / 2);
const CIRCLE_CENTER = CIRCLE_DIAMETER;

const SVG_SIZE = CIRCLE_DIAMETER * 2;
const BIG_CIRCLE_R_VAL = CIRCLE_DIAMETER * 0.5;
const MOTION_CIRCLE_R_VAL = CIRCLE_DIAMETER * 0.3;

const CIRCLE_LERP_EASE = 0.6;
const CIRCLE_MOTION_MULTIPLIER = 2; //Multiply the distance it should be
const MAX_WARP_DISTANCE = BIG_CIRCLE_R_VAL * 0.38;

const BEND_ID = 'bend';
const MASK_ID = 'mask';

const Cursor = ({ className }) => {
  const { innerWidth, innerHeight } = useWindowSize(resizeDebounceTime);
  const { isSafari } = detect.browser;
  const { isMouseDown, cursorState } = useSelector((state) => state.cursor);
  const { userIsValid } = useSelector((state) => state.user);
  const { cursor: COPY } = useCopy();
  const dispatch = useDispatch();
  const router = useRouter();
  const containerRef = useRef();
  const requestRef = useRef();
  const posRef = useRef({ x: 0, y: 0 });
  const posMotionRef = useRef({ x: 0, y: 0 });
  const targetRef = useRef({ x: 0, y: 0 });
  const caretRightRef = useRef();
  const caretLeftRef = useRef();
  const holdCircleRef = useRef();
  const textRefs = useRef({});
  const holdTextRefs = useRef({});
  const motionMaskRefs = useRef({ mask: null, bg: null });
  const [currentHoldTextKey, setCurrentHoldTextKey] = useState(holdKeys.HOLD);
  const fallbackRef = useRef();
  const showCursorInitially = useRef(false);
  const showCursorVisibly = useRef(false);
  const [allowRAF, setAllowRAF] = useState(false);

  const isMediaKit = router.route === routes.Press.path || router.route === routes.Reviews.path;
  const CIRCLE_STYLES = isMediaKit ? styles.mediaCircleLine : styles.circleLine;

  /* ========================================
  Adding/Removing cursor class from body
  because can't add pure selector to scss
  hehhhhh s'stupid
  ======================================== */

  useEffect(() => {
    const bod = document.body;

    bod.classList.add(HIDE_CURSOR_CLASS);

    return () => {
      bod.classList.remove(HIDE_CURSOR_CLASS);
    };
  }, []);

  /* ========================================
  Moving the cursor
  ======================================== */

  const showCursor = useCallback(() => {
    gsap.to(containerRef.current, {
      delay: 0.1,
      autoAlpha: 1,
      duration: 0.2
    });
  }, []);

  const hideCursor = useCallback(() => {
    gsap.to(containerRef.current, {
      autoAlpha: 0,
      duration: 0.2
    });
  }, []);

  const moveCursor = useCallback(() => {
    requestRef.current = requestAnimationFrame(moveCursor);

    posRef.current = {
      x: lerp(posRef.current.x, targetRef.current.x, CIRCLE_LERP_EASE),
      y: lerp(posRef.current.y, targetRef.current.y, CIRCLE_LERP_EASE)
    };

    let xDiff = targetRef.current.x - posRef.current.x;
    let yDiff = targetRef.current.y - posRef.current.y;

    xDiff = xDiff <= 0 ? Math.max(-MAX_WARP_DISTANCE, xDiff) : Math.min(MAX_WARP_DISTANCE, xDiff);
    yDiff = yDiff <= 0 ? Math.max(-MAX_WARP_DISTANCE, yDiff) : Math.min(MAX_WARP_DISTANCE, yDiff);

    posMotionRef.current = {
      x: -xDiff * CIRCLE_MOTION_MULTIPLIER,
      y: -yDiff * CIRCLE_MOTION_MULTIPLIER
    };

    if (!isSafari) {
      gsap.set(Object.values(motionMaskRefs.current), {
        x: posMotionRef.current.x,
        y: posMotionRef.current.y
      });

      //Kill RAF if not moving
      if (posMotionRef.current.x === 0 && posMotionRef.current.y === 0) {
        setAllowRAF(false);
      }
    }
  }, [isSafari, setAllowRAF]);

  useEffect(() => {
    const updateTargetRef = (e) => {
      const xCoord = e.changedTouches?.length ? e.changedTouches[0]?.clientX : e.clientX;
      const yCoord = e.changedTouches?.length ? e.changedTouches[0]?.clientY : e.clientY;

      //Allow raf on movement
      setAllowRAF(true);

      //Ensure not doing 0,0 coord
      if (xCoord && yCoord) {
        targetRef.current.x = xCoord;
        targetRef.current.y = yCoord;
      }

      const fadeThreshold = 15;
      if (
        xCoord < fadeThreshold ||
        xCoord > innerWidth - fadeThreshold ||
        yCoord < fadeThreshold ||
        yCoord > innerHeight - fadeThreshold
      ) {
        showCursorVisibly.current = false;
        hideCursor();
      } else {
        if (!showCursorVisibly.current) {
          showCursorVisibly.current = true;
          showCursor();
        }
      }

      gsap.set(containerRef.current, {
        x: targetRef.current.x - SVG_SIZE / 2,
        y: targetRef.current.y - SVG_SIZE / 2
      });

      if (!showCursorInitially.current) {
        showCursorInitially.current = true;
        showCursorVisibly.current = true;
        showCursor();
      }
    };

    document.addEventListener('mousemove', updateTargetRef);
    document.addEventListener('drag', updateTargetRef);

    if (isTouchDevice) {
      document.addEventListener('touchmove', updateTargetRef);
    }

    return () => {
      cancelAnimationFrame(requestRef.current);
      document.removeEventListener('mousemove', updateTargetRef);
      document.removeEventListener('drag', updateTargetRef);

      if (isTouchDevice) {
        document.removeEventListener('touchmove', updateTargetRef);
      }
    };
  }, [moveCursor, setAllowRAF, showCursor, hideCursor, innerWidth, innerHeight]);

  //kill raf, enable raf
  useEffect(() => {
    if (allowRAF) {
      moveCursor();
    } else if (!allowRAF && requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }
  }, [moveCursor, allowRAF]);

  /* ========================================
  Animating the cursor
  ======================================== */

  const animateInHoldText = useCallback((holdTextKey) => {
    const animateOutElKey = Object.keys(holdTextRefs.current).filter((key) => key !== holdTextKey)[0];
    const duration = 0.2;

    gsap.killTweensOf(holdTextRefs.current[animateOutElKey]);
    gsap.to(holdTextRefs.current[animateOutElKey], {
      autoAlpha: 0,
      duration
    });

    gsap.killTweensOf(holdTextRefs.current[holdTextKey]);
    gsap.to(holdTextRefs.current[holdTextKey], {
      autoAlpha: 1,
      duration,
      delay: duration
    });
  }, []);

  const animateHoldStateIn = useCallback(() => {
    gsap.to(holdCircleRef.current, {
      duration: 3,
      ease: 'linear',
      onComplete: () => {
        setCurrentHoldTextKey(holdKeys.RELEASE);
        dispatch(setIsHoldComplete(true));
      },
      attr: {
        'stroke-dashoffset': 0
      }
    });
  }, [dispatch, setCurrentHoldTextKey]);

  const animateHoldStateOut = useCallback(() => {
    dispatch(setIsHoldComplete(false));
    setCurrentHoldTextKey(holdKeys.HOLD);
    gsap.killTweensOf(holdCircleRef.current);
    gsap.to(holdCircleRef.current, {
      duration: 0.7,
      attr: {
        'stroke-dashoffset': CIRCLE_STROKE_DASH_ARR
      }
    });
  }, [dispatch, setCurrentHoldTextKey]);

  useEffect(() => {
    if (isMouseDown) {
      animateHoldStateIn();
    } else {
      animateHoldStateOut();
    }
  }, [isMouseDown, animateHoldStateIn, animateHoldStateOut]);

  const animateInit = useCallback(() => {
    if (!Object.values(textRefs.current).includes(undefined)) {
      gsap.set(Object.values(textRefs.current), {
        autoAlpha: 0
      });
    }

    //hide the "release" text inside the hidden div
    gsap.set(holdTextRefs.current[holdKeys.RELEASE], {
      autoAlpha: 0
    });

    gsap.set([caretRightRef.current, caretLeftRef.current], {
      autoAlpha: 0
    });

    //Hide entire container
    gsap.set(containerRef.current, {
      autoAlpha: 0
    });
  }, []);

  useEffect(() => {
    animateInit();
  }, [animateInit]);

  const animateCursorHoverState = useCallback(
    (cursorState) => {
      if (!containerRef?.current) return;

      const textAnimDuration = 0.3;

      const defaultSize = {
        scale: 1
      };

      const hideText = (el) => {
        if (!el) return;
        if (el.includes(undefined)) return;

        gsap.to(el, {
          autoAlpha: 0,
          duration: textAnimDuration,
          ease: cursorEase
        });
      };

      const showText = (el) => {
        if (!el) return;

        gsap.to(el, {
          autoAlpha: 1,
          duration: textAnimDuration,
          ease: cursorEase
        });
      };

      const resetCarets = () => {
        gsap.to([caretRightRef.current, caretLeftRef.current], {
          x: 0,
          autoAlpha: 0
        });
      };

      const showCarets = () => {
        const distance = 8;
        gsap.to([caretRightRef.current, caretLeftRef.current], {
          autoAlpha: 1
        });

        gsap.to(caretRightRef.current, {
          x: -1 * distance,
          ease: 'power1.inout'
        });

        gsap.to(caretLeftRef.current, {
          x: distance,
          ease: 'power1.inout'
        });
      };

      const expandCarets = () => {
        gsap.to([caretRightRef.current, caretLeftRef.current], {
          x: 0,
          ease: 'power1.inout'
        });
      };

      switch (cursorState) {
        case cursorKeys.STATIC:
          animateHoldStateOut();
          resetCarets();
          hideText(Object.values(textRefs.current));
          gsap.to(containerRef.current, {
            ...defaultSize
          });
          break;
        case cursorKeys.FOCUS:
          animateHoldStateOut();
          resetCarets();
          hideText(Object.values(textRefs.current));
          gsap.to(containerRef.current, {
            scale: 0.5,
            ease: cursorEase
          });
          break;
        case cursorKeys.CLOSE:
          animateHoldStateOut();
          resetCarets();
          hideText([textRefs.current._360, textRefs.current.HOLD]);
          showText(textRefs.current.CLOSE);
          gsap.to(containerRef.current, {
            ...defaultSize,
            scale: 1.15
          });
          break;
        case cursorKeys.HOLD:
          resetCarets();
          hideText([textRefs.current._360, textRefs.current.CLOSE]);
          showText(textRefs.current.HOLD);
          gsap.to(containerRef.current, {
            ...defaultSize
          });
          break;
        case cursorKeys._360:
          animateHoldStateOut();
          showCarets();
          hideText([textRefs.current.HOLD, textRefs.current.CLOSE]);
          showText(textRefs.current._360);
          gsap.to(containerRef.current, {
            ...defaultSize,
            scale: 1.15
          });
          break;
        case cursorKeys._360_HOLD:
          animateHoldStateOut();
          expandCarets();
          hideText([textRefs.current.HOLD, textRefs.current.CLOSE]);
          showText(textRefs.current._360);
          gsap.to(containerRef.current, {
            ...defaultSize
          });
          break;
        default:
          break;
      }
    },
    [animateHoldStateOut]
  );

  useEffect(() => {
    animateCursorHoverState(cursorState);
  }, [animateCursorHoverState, cursorState]);

  useEffect(() => {
    animateInHoldText(currentHoldTextKey);
  }, [currentHoldTextKey, animateInHoldText]);

  return (
    <div className={classnames(styles.Cursor, className)} ref={containerRef}>
      <div className={styles.inner}>
        <div className={classnames(styles.caretContainer, styles.caretContainerRight)} ref={caretRightRef}>
          <Caret className={classnames(styles.caret, styles.caretRight)} />
        </div>
        <div className={classnames(styles.caretContainer, styles.caretContainerLeft)} ref={caretLeftRef}>
          <Caret className={classnames(styles.caret, styles.caretLeft)} />
        </div>
        <svg
          width={SVG_SIZE}
          height={SVG_SIZE}
          viewBox={`0 0 ${SVG_SIZE} ${SVG_SIZE}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          suppressHydrationWarning={true}
          aria-label="cursor"
        >
          <defs>
            <filter id={BEND_ID}>
              <feGaussianBlur in="SourceGraphic" stdDeviation={6} result="blur" />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -14"
                result="goo"
              />
              <feComposite in="SourceGraphic" in2="goo" operator="atop" />
            </filter>
          </defs>
          {/* Mask to create illusion of stroke. Shapes are slightly smaller than shapes below */}
          {!isSafari && (
            <mask id={MASK_ID}>
              <rect x="0" y="0" width={SVG_SIZE} height={SVG_SIZE} fill="white" />
              <g filter={`url(#${BEND_ID})`}>
                {/* BEND FILTER MOTION CIRCLE */}
                <circle
                  cx={CIRCLE_CENTER}
                  cy={CIRCLE_CENTER}
                  r={MOTION_CIRCLE_R_VAL * 0.99 * 1.23}
                  fill="black"
                  ref={(ref) => (motionMaskRefs.current.mask = ref)}
                />
                {/* BEND FILTER NORMAL CIRCLE */}
                <circle cx={CIRCLE_CENTER * 0.995} cy={CIRCLE_CENTER} r={BIG_CIRCLE_R_VAL * 0.99 * 1.24} fill="black" />
              </g>
            </mask>
          )}

          {isSafari ? (
            <circle
              cx={CIRCLE_CENTER}
              cy={CIRCLE_CENTER}
              r={BIG_CIRCLE_R_VAL}
              strokeWidth={CIRCLE_STROKE_WIDTH * 0.7}
              className={classnames(styles.circleFallback, { [styles.mediaKit]: isMediaKit })}
              ref={fallbackRef}
            />
          ) : (
            <g mask={`url(#${MASK_ID})`} filter={`url(#${BEND_ID})`}>
              {/* NORMAL CIRCLE */}
              <circle
                cx={CIRCLE_CENTER}
                cy={CIRCLE_CENTER}
                r={BIG_CIRCLE_R_VAL * 1.25}
                strokeWidth={CIRCLE_STROKE_WIDTH}
                className={classnames(CIRCLE_STYLES, styles.staticCircle, {
                  [styles.media]: userIsValid && isMediaKit
                })}
                strokeDasharray={CIRCLE_DIAMETER * Math.PI}
                strokeDashoffset={0}
              />
              {/* MOTION CIRCLE */}
              <circle
                cx={CIRCLE_CENTER}
                cy={CIRCLE_CENTER}
                r={MOTION_CIRCLE_R_VAL * 1.25}
                strokeWidth={CIRCLE_STROKE_WIDTH}
                className={classnames(CIRCLE_STYLES, styles.staticCircle, {
                  [styles.media]: userIsValid && isMediaKit
                })}
                strokeDasharray={CIRCLE_DIAMETER * Math.PI}
                strokeDashoffset={0}
                ref={(ref) => (motionMaskRefs.current.bg = ref)}
              />
            </g>
          )}
          {/* HOLD CIRCLE */}
          <circle
            cx={CIRCLE_CENTER}
            cy={CIRCLE_CENTER}
            r={BIG_CIRCLE_R_VAL}
            strokeWidth={CIRCLE_STROKE_WIDTH * 2.5}
            className={classnames(CIRCLE_STYLES, styles.holdCircle)}
            strokeDasharray={CIRCLE_DIAMETER * Math.PI}
            strokeDashoffset={CIRCLE_STROKE_DASH_ARR}
            ref={holdCircleRef}
          />
        </svg>
        <p ref={(ref) => (textRefs.current[cursorKeys.CLOSE] = ref)} className={styles.text}>
          {COPY.close}
        </p>
        <p
          ref={(ref) => (textRefs.current[cursorKeys.HOLD] = ref)}
          className={classnames(styles.text, styles.textHold)}
        >
          <span ref={(ref) => (holdTextRefs.current[holdKeys.HOLD] = ref)}>{COPY._hold}</span>
          <span ref={(ref) => (holdTextRefs.current[holdKeys.RELEASE] = ref)}>{COPY._release}</span>
        </p>
        <p ref={(ref) => (textRefs.current[cursorKeys._360] = ref)} className={classnames(styles.text, styles.text360)}>
          {COPY._360}
          <sup>&deg;</sup>
        </p>
      </div>
    </div>
  );
};

Cursor.propTypes = checkProps({
  className: PropTypes.string
});

Cursor.defaultProps = {};

export default memo(Cursor);
