import React, { memo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import checkProps from '@jam3/react-check-extra-props';
import { gsap } from 'gsap';
import noop from 'no-op';

import { cursorKeys } from '../../keys/cursor';

import { setCursorState, setHoverAreaId } from '../../redux';

import styles from './PillButton.module.scss';

function PillButton({ className, text, icon, isActive, onButtonClick, href, download, disabled }) {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const animateIn = useCallback(() => {
    const node = ref.current;
    gsap.fromTo(
      node,
      {
        opacity: 0
      },
      {
        opacity: 1
      }
    );
  }, []);

  const animateOut = useCallback(() => {
    const node = ref.current;
    gsap.fromTo(
      node,
      {
        opacity: 1
      },
      {
        opacity: 0
      }
    );
  }, []);

  const handleMouseLeave = useCallback(() => {
    dispatch(setCursorState(cursorKeys.STATIC));
    dispatch(setHoverAreaId(null));
  }, [dispatch]);

  useEffect(() => {
    if (isActive) {
      animateIn();
    } else {
      animateOut();
    }

    return () => {
      handleMouseLeave();
    };
  }, [isActive, handleMouseLeave, animateIn, animateOut]);

  function renderIcon(Icon) {
    return <Icon />;
  }

  const handleClick = useCallback(() => {
    onButtonClick();
  }, [onButtonClick]);

  if (href) {
    return (
      <a
        href={href}
        download={download}
        className={classnames(styles.PillButton, className, { [styles.withIcon]: icon ? true : false })}
        ref={ref}
        onClick={handleClick}
      >
        {icon ? renderIcon(icon) : null}

        {text}
      </a>
    );
  }

  return (
    <button
      className={classnames(styles.PillButton, className, { [styles.withIcon]: icon ? true : false })}
      ref={ref}
      onClick={handleClick}
      disabled={disabled}
    >
      {icon ? renderIcon(icon) : null}
      <div className={styles.text}>{text}</div>
    </button>
  );
}

PillButton.propTypes = checkProps({
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.func,
  isActive: PropTypes.bool,
  onButtonClick: PropTypes.func,
  href: PropTypes.string,
  linkProps: PropTypes.func,
  download: PropTypes.bool,
  disabled: PropTypes.bool
});

PillButton.defaultProps = {
  text: 'click here',
  icon: null,
  isActive: false,
  onButtonClick: noop
};

export default memo(PillButton);
