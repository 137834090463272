export const holdKeys = {
  HOLD: 'HOLD',
  RELEASE: 'RELEASE'
};

export const cursorKeys = {
  STATIC: 'STATIC',
  FOCUS: 'FOCUS',
  CLOSE: 'CLOSE',
  HOLD: 'HOLD',
  _360: '_360',
  _360_HOLD: '_360_HOLD'
};
