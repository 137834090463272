import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { useSelector } from 'react-redux';
import { gsap } from 'gsap';

import { isMobile } from '../../utils/detect';
import useHeader from '../../utils/hooks/use-header';

import SonosLogo from '../SonosLogo/SonosLogo';
import SoundButton from '../SoundButton/SoundButton';
import alignmentKeys from '../../keys/alignment';
import MediaKitButton from '../MediaKitButton/MediaKitButton';

import headerKeys from '../../keys/header';

import { COLORS } from '../../data/constants';

import styles from './TopNav.module.scss';

const THEME = {
  [headerKeys.WHITE]: COLORS.WHITE,
  [headerKeys.PEACH]: COLORS.PEACH
};

function TopNav({ className, onMediaClick, handleOnSonosLogoClick }) {
  const { shouldRenderHeader, shouldRenderMediaKitButton } = useHeader();
  const { headerTheme } = useSelector((state) => state.header);
  const containerRef = useRef();

  const changeHeaderTheme = useCallback((theme) => {
    const container = containerRef.current;
    const svgPaths = container.querySelectorAll('path');
    const links = container.querySelectorAll('a, button');

    gsap.to([...svgPaths, ...links], {
      fill: THEME[theme],
      color: THEME[theme],
      duration: 0.6
    });
  }, []);

  useEffect(() => {
    shouldRenderHeader && changeHeaderTheme(headerTheme);
  }, [shouldRenderHeader, changeHeaderTheme, headerTheme]);

  const handleMediaClick = useCallback(async () => {
    onMediaClick(false);
  }, [onMediaClick]);

  return (
    <>
      {shouldRenderHeader && (
        <div ref={containerRef} className={classnames(styles.topNav, className)}>
          {!isMobile && (
            <>
              <SonosLogo alignment={alignmentKeys.CENTER} onClick={handleOnSonosLogoClick} />
              <nav>
                {shouldRenderMediaKitButton && <MediaKitButton onClick={handleMediaClick} />}
                {shouldRenderMediaKitButton && <SoundButton />}
              </nav>
            </>
          )}
        </div>
      )}
    </>
  );
}

TopNav.propTypes = checkProps({
  className: PropTypes.string,
  onMediaClick: PropTypes.func,
  handleOnSonosLogoClick: PropTypes.func
});

TopNav.defaultProps = {};

export default memo(TopNav);
