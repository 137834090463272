// Add small functions you use as utils here

export function cleanUrl(path = '', cleanParams) {
  if (!path) return '';
  if (cleanParams) path = path?.split('?')[0];
  if (path === '/') return '/';
  return path.replace(/\/$/, '').replace(/^\//, '') || '';
}

export function debounce(callback, delay) {
  let timeout = null;
  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, delay);
  };
}

export const lerp = (currentValue, targetValue, ease) => currentValue + (targetValue - currentValue) * ease;

export const simpleImagesPreload = (urls, allImagesLoadedCallback) => {
  let loadedCounter = 0;
  const toBeLoadedNumber = urls.length;

  urls.forEach(function (url) {
    preloadImage(url, function () {
      loadedCounter++;
      if (loadedCounter === toBeLoadedNumber) {
        allImagesLoadedCallback();
      }
    });
  });

  function preloadImage(url, anImageLoadedCallback) {
    const img = new Image();
    img.onload = anImageLoadedCallback;
    img.src = url;
  }
};

export const preloadVideo = (url, mimeType) => {
  const video = document.createElement('video');

  const xhr = new XMLHttpRequest();

  xhr.open('GET', url, true);
  xhr.responseType = 'arraybuffer';

  xhr.onload = function (oEvent) {
    const blob = new Blob([oEvent.target.response], { type: `video/${mimeType}` });

    video.src = URL.createObjectURL(blob);
  };

  xhr.send();
};
