import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeSlide: -1,
  isNavHovered: false,
  navIsActive: true
};

export const slideSlice = createSlice({
  name: 'slide',
  initialState: initialState,
  reducers: {
    setActiveSlide(state, action) {
      return { ...state, activeSlide: action.payload };
    },
    setNavHovered(state, action) {
      return { ...state, isNavHovered: action.payload };
    },
    setNavIsActive(state, action) {
      return { ...state, navIsActive: action.payload };
    }
  }
});
