import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import fetcher from '../fetcher';

import apiRoutes from '../../data/api';
import routes from '../../data/routes';

import { setUserIsValid } from '../../redux';

const REFRESH_PING_INTERVAL = 120000; //2 minutes

const usePageGate = () => {
  const pageGateDisabled = process.env.NEXT_PUBLIC_PAGEGATE_DISABLED === 'true';
  const dispatch = useDispatch();
  const router = useRouter();
  const [isLoadingPing, setIsLoadingPing] = useState(true);
  const { data: pingDataResponse, error: pingError } = useSWR(!pageGateDisabled ? apiRoutes.PING : null, fetcher, {
    refreshInterval: REFRESH_PING_INTERVAL
  });

  const requiresPreload = router.pathname === routes.Home.path || router.pathname === routes.Explore.path;
  const goStraightToPage =
    router.pathname === routes.Reviews.path ||
    router.pathname === routes.Press.path ||
    router.pathname === routes._404.path;

  useEffect(() => {
    if (pingDataResponse === undefined) return;

    dispatch(setUserIsValid(pingDataResponse.status === 200 ? true : false));
    setIsLoadingPing(false);
  }, [setIsLoadingPing, dispatch, pingDataResponse]);

  return {
    isLoadingPing,
    pingError,
    requiresPreload,
    goStraightToPage,
    pageGateDisabled
  };
};

export default usePageGate;
