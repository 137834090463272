import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import gsap from 'gsap';

import { useSelector, useDispatch } from 'react-redux';
import { setIsSoundOn } from '../../redux';

import styles from './SoundButton.module.scss';
import BaseButton from '../BaseButton/BaseButton';
import HoverArea from '../HoverArea/HoverArea';

import SoundOffIcon from '../../assets/svgs/svg-soundOff.svg';
import SoundOnIcon from '../../assets/svgs/svg-soundOn.svg';

import { cursorKeys } from '../../keys/cursor';
import { TRANSITION_TIME } from '../../data/constants';

function SoundButton({ className }) {
  const dispatch = useDispatch();
  const { isSoundOn } = useSelector((state) => state.sound);
  const { userIsValid } = useSelector((state) => state.user);

  const soundButtonRef = useRef(null);

  useEffect(() => {
    gsap.to(soundButtonRef.current, {
      opacity: 1,
      duration: 1,
      delay: userIsValid ? 0.1 + TRANSITION_TIME * 2.5 : TRANSITION_TIME * 2
    });
  }, [userIsValid]);

  return (
    <div className={classnames(styles.SoundButton, className)}>
      <HoverArea cursorType={cursorKeys.FOCUS}>
        <BaseButton
          className={classnames(styles.button)}
          ref={soundButtonRef}
          onClick={() => {
            dispatch(setIsSoundOn(!isSoundOn));
          }}
        >
          <div className={styles.buttonText}>{isSoundOn ? <span>Sound On</span> : <span>Sound Off</span>}</div>
          <div className={styles.icon}>
            {isSoundOn ? <SoundOnIcon className={styles.iconImage} /> : <SoundOffIcon className={styles.iconImage} />}
          </div>
        </BaseButton>
      </HoverArea>
    </div>
  );
}

SoundButton.propTypes = checkProps({
  className: PropTypes.string
});

SoundButton.defaultProps = {};

export default memo(SoundButton);
