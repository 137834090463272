export default {
  introducing: 'Enter password to continue to the experience',
  title: 'Welcome',
  skip: 'View Media Kit',
  enterExperience: 'Enter',
  error: {
    incorrectPassword: 'Incorrect Password.',
    enterValidPassword: 'Enter a valid password.',
    unknownError: "Whoops - there's been an error."
  },
  password: 'Password',
  submitting: 'Submitting...',
  enter: 'Enter'
};
