import noop from 'no-op';

function Debug() {
  const debug = {};

  if (process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_ENVIRONMENT === 'develop') {
    for (let prop in console) {
      if (typeof console[prop] === 'function') {
        debug[prop] = console[prop];
      }
    }
  } else {
    for (let prop in console) {
      if (typeof console[prop] === 'function') {
        debug[prop] = noop;
      }
    }
  }

  return debug;
}

export { Debug };
