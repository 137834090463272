import { Scene, PerspectiveCamera, Vector3, Color } from 'three';
import { gsap, Cubic } from 'gsap';

export default class BaseScene {
  constructor(name = 'defaultScene', cameraRatio = 1, type) {
    this.id = name;
    this.scene = new Scene();
    this.type = type;

    this.cameraLookTarget = new Vector3();
    this.camera = new PerspectiveCamera(40, cameraRatio, 0.01, 1000);
    this.backgroundColor = 0x000000;
    this.timer = 0;
    this.zoomRatio = 1;
    this.outMousePosition = new Vector3(50.0, 50.0, 50.0, 50.0);
    this.mousePosition = new Vector3(50.0, 50.0, 50.0, 50.0);
    this.mouseWoldPosition = new Vector3();

    this.active = false;
    this.resize();
  }

  setup = () => {};

  moveCamera = (position, time, delay = 0, ease = Cubic.easeInOut, callback) => {
    gsap.killTweensOf(this.camera.position);
    gsap.to(this.camera.position, {
      duration: time,
      x: position.x,
      y: position.y,
      z: position.z,
      ease: ease,
      delay: delay,
      onComplete: callback
    });
  };

  setCameraPosition = (position, lookAt) => {
    this.camera.position.set(position.x, position.y, position.z);
    this.cameraLookTarget.set(lookAt.x, lookAt.y, lookAt.z);
    this.camera.lookAt(this.cameraLookTarget);
  };

  setCameraFOV = (fov) => {
    if (this.camera) {
      this.camera.fov = fov;
      this.camera.updateProjectionMatrix();
    }
  };

  setBackgroundColor = (color = 0x000000, time = 0.5, delay = 0) => {
    let currentColor = new Color(this.backgroundColor);
    let newColor = new Color(color);
    gsap.to(currentColor, {
      duration: time,
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      ease: Cubic.easeInOut,
      delay: delay,
      onUpdate: () => {
        this.backgroundColor = currentColor.getHex();
      }
    });
  };

  setElementColor = (color = 0x000000, time = 0.5, delay = 0) => {
    if (this.elements && this.id !== 'ProductScene') {
      let currentColor = this.elements.getMaterialColor();
      let newColor = new Color(color);
      gsap.to(currentColor, {
        duration: time,
        x: newColor.r,
        y: newColor.g,
        z: newColor.b,
        ease: Cubic.easeInOut,
        delay: delay,
        onUpdate: () => {
          this.elements.updateMaterialColor(currentColor);
        }
      });
    }
  };

  setLightColor = (color = 0x000000, time = 0.5, delay = 0) => {
    if (this.elements && this.id !== 'ProductScene') {
      let currentColor = this.elements.getMaterialLightColor();
      let newColor = new Color(color);
      gsap.to(currentColor, {
        duration: time,
        x: newColor.r,
        y: newColor.g,
        z: newColor.b,
        ease: Cubic.easeInOut,
        delay: delay,
        onUpdate: () => {
          this.elements.updateMaterialLightColor(currentColor);
        }
      });
    }
  };

  getMouseWorldPosition = (x, y, z) => {
    this.mouseWoldPosition.x = x;
    this.mouseWoldPosition.y = y;
    this.mouseWoldPosition.z = z;

    var vector = this.mouseWoldPosition;
    vector.unproject(this.camera);
    var dir = vector.sub(this.camera.position).normalize();
    var distance = -this.camera.position.z / dir.z;
    var pos = this.camera.position.clone().add(dir.multiplyScalar(distance));

    return pos;
  };

  setColorScheme = (colorSet, time, delay) => {
    this.setBackgroundColor(colorSet.backgroundColor, time, delay);
    this.setElementColor(colorSet.elementColor, time, delay);
    this.setLightColor(colorSet.lightColor, time, delay);
  };

  animateIn = (time = 1.0, delay = 0.0) => {};
  animateOut = (time = 1.0, delay = 0.0) => {};
  startVisualization = () => {};
  stopVisualization = () => {};

  resize = (ratio, zoomRatio) => {
    this.zoomRatio = zoomRatio;
    this.camera.aspect = ratio;
    this.camera.updateProjectionMatrix();
  };

  update = (delta, controller) => {};

  dispose = () => {};
}
