export default {
  Home: {
    path: '/',
    title: 'Sonos Press Event'
  },
  Explore: {
    path: '/explore',
    title: 'Sonos Press Event'
  },
  Press: {
    path: '/press',
    title: 'Sonos Media Kit'
  },
  Reviews: {
    path: '/reviews',
    title: 'Sonos Press Reviews'
  },
  _404: {
    path: '/404',
    title: '404'
  },
  unsupported: {
    path: '/unsupported',
    title: 'Unsupported'
  }
};
