import React, { memo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import checkProps from '@jam3/react-check-extra-props';
import { gsap } from 'gsap';
import noop from 'no-op';
import HoverArea from '../HoverArea/HoverArea';

import { setCursorState, setHoverAreaId } from '../../redux';

import { ease1 } from '../../data/eases';
import { cursorKeys } from '../../keys/cursor';

import styles from './EnterPillButton.module.scss';

const MAIN_ANIMATION_DURATION = 0.667;
const SECONDARY_ANIMATION_DURATION = 0.334;

function EnterPillButton({ className, text, isActive, delay, onButtonClick }) {
  const ref = useRef(null);
  const mainTextRef = useRef(null);
  const circleRef = useRef(null);
  const secondaryTextRef = useRef(null);
  const isHovering = useRef(null);
  const hoverAnimation = useRef(null);

  const dispatch = useDispatch();
  isHovering.current = false;

  const animateIn = useCallback(() => {
    const node = ref.current;
    gsap.fromTo(
      node,
      {
        opacity: 0
      },
      {
        opacity: 1,
        delay: delay ? delay : 0
      }
    );
  }, [delay]);

  const animateOut = useCallback(() => {
    const node = ref.current;
    gsap.fromTo(
      node,
      {
        opacity: 1
      },
      {
        opacity: 0
      }
    );
  }, []);

  const animateMouseEnter = useCallback(() => {
    isHovering.current = true;

    if (!hoverAnimation.current) {
      hoverAnimation.current = gsap.timeline({
        onComplete: () => {
          hoverAnimation.current = null;

          if (!isHovering.current) {
            animateMouseLeave();
          }
        }
      });

      hoverAnimation.current.to(
        mainTextRef.current,
        {
          duration: SECONDARY_ANIMATION_DURATION,
          autoAlpha: 0,
          ease: ease1
        },
        0
      );

      hoverAnimation.current.to(
        circleRef.current,
        {
          scale: 1,
          duration: MAIN_ANIMATION_DURATION,
          ease: ease1
        },
        0
      );

      hoverAnimation.current.to(
        secondaryTextRef.current,
        {
          autoAlpha: 1,
          delay: 0.234,
          translateY: 0,
          duration: MAIN_ANIMATION_DURATION
        },
        0
      );
    }
    // eslint-disable-next-line no-use-before-define
  }, [animateMouseLeave]);

  const animateMouseLeave = useCallback(() => {
    isHovering.current = false;

    if (!hoverAnimation.current) {
      hoverAnimation.current = gsap.timeline({
        onComplete: () => {
          hoverAnimation.current = null;

          if (isHovering.current) {
            animateMouseEnter();
          }

          if (mainTextRef.current && secondaryTextRef.current) {
            gsap.set(mainTextRef.current, {
              autoAlpha: 1
            });

            gsap.set(secondaryTextRef.current, {
              autoAlpha: 0,
              color: '#65303d',
              translateY: '10px'
            });
          }
        }
      });

      hoverAnimation.current.to(
        secondaryTextRef.current,
        {
          color: '#febca5',
          duration: MAIN_ANIMATION_DURATION
        },
        0
      );

      hoverAnimation.current.to(
        circleRef.current,
        {
          scale: 0,
          duration: MAIN_ANIMATION_DURATION
        },
        0
      );
    }
  }, [animateMouseEnter]);

  const handleMouseLeave = useCallback(() => {
    dispatch(setCursorState(cursorKeys.STATIC));
    dispatch(setHoverAreaId(null));
  }, [dispatch]);

  useEffect(() => {
    if (isActive) {
      animateIn();
    } else {
      animateOut();
    }

    return () => {
      handleMouseLeave();
    };
  }, [isActive, handleMouseLeave, animateIn, animateOut]);

  const handleClick = useCallback(() => {
    onButtonClick();
  }, [onButtonClick]);

  return (
    <HoverArea cursorType={cursorKeys.FOCUS}>
      <button
        className={classnames(styles.EnterPillButton, className)}
        ref={ref}
        onMouseEnter={animateMouseEnter}
        onMouseLeave={animateMouseLeave}
        onClick={handleClick}
      >
        <div className={styles.text} ref={mainTextRef}>
          {text}
        </div>
        <div className={styles.circle} ref={circleRef} />
        <div className={styles.wrapper}>
          <div className={styles.secondaryText} ref={secondaryTextRef}>
            {text}
          </div>
        </div>
      </button>
    </HoverArea>
  );
}

EnterPillButton.propTypes = checkProps({
  className: PropTypes.string,
  text: PropTypes.string,
  delay: PropTypes.number,
  isActive: PropTypes.bool,
  onButtonClick: PropTypes.func
});

EnterPillButton.defaultProps = {
  text: 'click here',
  isActive: false,
  onButtonClick: noop
};

export default memo(EnterPillButton);
