import { ShaderMaterial, Vector4, Vector2, Vector3, Color } from 'three';
import ringVert from '../../../glsl/ring.vert';
import ringFrag from '../../../glsl/ring.frag';

const RingMaterial = (opts = {}) => {
  let color = new Color(opts.color || 0x000000);
  color = new Vector4(color.r, color.g, color.b, 1.0);

  let lightColor = new Color(opts.lightColor || 0x000000);
  lightColor = new Vector4(lightColor.r, lightColor.g, lightColor.b, 1.0);

  const uniforms = {
    uColor: { value: color },
    uLightColor: { value: lightColor },
    uLightIntensity: { value: 0.3 },
    uLightRadius: { value: 3.0 },
    uLightSpread: { value: 0.0 },
    uOpacity: { value: new Vector4(1.0, 0.0, 0.0, 0.0) },
    uPulseTime: { value: 0.0 },
    uIdle: { value: 0.0 },
    uTime: { value: 0.0 },
    uMouse: { value: new Vector4(50.0, 50.0, 50.0, 50.0) },
    uDirection: { value: new Vector3(0.0, 0.0, 0.0) },
    uDistortionIntensity: { value: new Vector4(2.3, 2.3, 2.3, 1.0) },
    uDistortionAmount: { value: new Vector4(3.0, 1.0, 1.0, 1.0) },
    uPulseColorType: { value: 0.0 },
    uPulseColorAmount: { value: new Vector2(2.3, 0.5) },
    uPulsePositionType: { value: 0.0 },
    uPulseFrequency: { value: new Vector4(2.3, 2.3, 2.3, 1.1) },
    uPulseIntensity: { value: new Vector4(2, 2, 2, 0.2) },
    uPulseSpread: { value: 0.2 },
    uAmount: { value: 0.0 }
  };

  return new ShaderMaterial({
    uniforms: uniforms,
    transparent: true,
    vertexShader: ringVert,
    fragmentShader: ringFrag
  });
};

export default RingMaterial;
