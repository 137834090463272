import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import wait from '@jam3/wait';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { gsap } from 'gsap';

import styles from './PageGateWrapper.module.scss';

import PasswordGate from '../../components/PasswordGate/PasswordGate';
import Preloader, { ANIMATE_IN_OUT_DURATION } from '../Preloader/Preloader';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Cursor from '../Cursor/Cursor';
import TopNav from '../TopNav/TopNav';
import Head from '../Head/Head';
import WipeElement from '../WipeElement/WipeElement';
import useWipeTransition from '../../utils/hooks/use-wipe-transition';

import pagegateKeys from '../../keys/pagegate';
import routes from '../../data/routes';
import { TRANSITION_TIME } from '../../data/constants';

import soundtrack from '../../assets/sound/ambient_soundtrack.mp3';
import oggSoundtrack from '../../assets/sound/ambient_soundtrack.ogg';

import usePageGate from '../../utils/hooks/use-page-gate';

import { setActiveSlide, setProduct, setShowHotspot, setUserIsValid, setWebGLScene } from '../../redux';
import { PRODUCT_TYPES, SCENE_TYPES } from '../WebGL/WebGLApp/utils/constants';
import { isTouchDevice } from '../../utils/detect';

const TRANSITION_TIME_MS = TRANSITION_TIME * 1000;

function PageGateWrapper({ className, children }) {
  useWipeTransition(true);
  const router = useRouter();
  const dispatch = useDispatch();

  const [showInitialGate, setShowInitialGate] = useState(false);
  const [showPreloader, setShowPreloader] = useState(false);
  const [mountPassgateChildren, setMountPassgateChildren] = useState(false);
  const [mountPassgateContainer, setMountPassgateContainer] = useState(true);
  const [showPreloaderVisibly, setShowPreloaderVisibly] = useState(true);
  const [showChildren, setShowChildren] = useState(false);
  const [currentPagegateStep, setCurrentPagegateStep] = useState(null);
  const [currentPage, setCurrentPage] = useState(routes.Home.title);

  const { percentLoaded, loadingComplete, webGLReady } = useSelector((state) => state.webGL);
  const { activeSlide } = useSelector((state) => state.slide);
  const { isSoundOn } = useSelector((state) => state.sound);
  const { isAutoPlay, isPersonaHoldPlaying } = useSelector((state) => state.video);
  const soundRef = useRef();
  const soundTlRef = useRef();
  const childrenContainerRef = useRef();
  const passGateContainerRef = useRef();
  const prevPaths = useRef([]);
  const passwordGateRef = useRef();

  //Passgate variables
  let { isLoadingPing, requiresPreload, goStraightToPage, pageGateDisabled } = usePageGate();
  let { userIsValid } = useSelector((state) => state.user);

  //Setting certain variables based on whether passgate is enabled
  userIsValid = pageGateDisabled ? true : userIsValid;
  isLoadingPing = pageGateDisabled ? false : isLoadingPing;

  /* =============================================
  Animate items in
  ============================================= */

  const visiblyHideMainElements = useCallback(() => {
    if (!passGateContainerRef.current) return;
    gsap.set(passGateContainerRef.current, {
      autoAlpha: 0
    });
  }, []);

  const visiblyShowMainElements = useCallback(() => {
    if (!passGateContainerRef.current) return;
    gsap.set(passGateContainerRef.current, {
      autoAlpha: 1
    });
  }, []);

  const fadeOutMainElements = useCallback(() => {
    if (!passGateContainerRef.current) return;
    gsap.to(passGateContainerRef.current, {
      autoAlpha: 0,
      duration: TRANSITION_TIME
    });
  }, []);

  const childrenAnimateInInit = useCallback(() => {
    if (!childrenContainerRef.current) return;
    gsap.set(childrenContainerRef.current, {
      autoAlpha: 0
    });
  }, []);

  const childrenAnimateIn = useCallback(() => {
    if (!childrenContainerRef.current) return;
    gsap.to(childrenContainerRef.current, {
      autoAlpha: 1,
      duration: TRANSITION_TIME,
      delay: TRANSITION_TIME * 2
    });
  }, []);

  const fadeOutAndUnmountPagegate = useCallback(async () => {
    if (!passGateContainerRef?.current) return;

    setShowInitialGate(false);
    fadeOutMainElements();

    await wait(TRANSITION_TIME_MS);
    setMountPassgateChildren(false);
    setMountPassgateContainer(false);
  }, [setShowInitialGate, fadeOutMainElements, setMountPassgateChildren, setMountPassgateContainer]);

  /* =============================================
  Handlers
  ============================================= */

  const handleOnSonosLogoClick = useCallback(() => {
    router.push(routes.Explore.path);
    fadeOutAndUnmountPagegate();
    setShowChildren(true);
  }, [fadeOutAndUnmountPagegate, router, setShowChildren]);

  const handleOnMediaClick = useCallback(() => {
    router.push(routes.Explore.path);
    fadeOutAndUnmountPagegate();
    dispatch(setShowHotspot(false));
    if (webGLReady) {
      dispatch(setWebGLScene(SCENE_TYPES.PRODUCT));
      dispatch(setProduct(PRODUCT_TYPES.WHITE));
    }
    dispatch(setActiveSlide(3));
    setShowChildren(true);
  }, [fadeOutAndUnmountPagegate, dispatch, setShowChildren, router, webGLReady]);

  const handleSkipToMediaKitClick = useCallback(async () => {
    router.push(routes.Press.path);
    fadeOutAndUnmountPagegate();
    setShowChildren(true);
  }, [fadeOutAndUnmountPagegate, setShowChildren, router]);

  const handlePasswordSuccess = useCallback(async () => {
    setShowInitialGate(false);

    await wait(TRANSITION_TIME_MS);
    dispatch(setUserIsValid(true));

    if (requiresPreload) {
      setShowPreloader(true);
    }
  }, [dispatch, requiresPreload]);

  const handleEnterClick = useCallback(async () => {
    router.push(routes.Explore.path);
    fadeOutAndUnmountPagegate();

    if (webGLReady) {
      dispatch(setWebGLScene(SCENE_TYPES.PRODUCT));
      dispatch(setProduct(PRODUCT_TYPES.EMPTY));
    }

    await wait(TRANSITION_TIME_MS);
    setShowChildren(true);

    dispatch(setActiveSlide(0));
    dispatch(setShowHotspot(false));
  }, [router, dispatch, setShowChildren, fadeOutAndUnmountPagegate, webGLReady]);

  const handleOnLoadComplete = useCallback(async () => {
    setCurrentPagegateStep(pagegateKeys.ENTER);

    //Set init the animation
    if (passwordGateRef.current) {
      passwordGateRef.current.enterAnimateInit();
    }

    //If they've been to press just show animation immediately instead of waiting
    if (prevPaths.current.includes(routes.Press.path) && passwordGateRef.current) {
      passwordGateRef.current.enterAnimateIn();
    }

    await wait(TRANSITION_TIME_MS + 1800); // +1800 takes into consideration rings animate-out time
    setShowPreloaderVisibly(false);
    setShowInitialGate(true);

    if (passwordGateRef.current) {
      passwordGateRef.current.enterAnimateIn();
    }

    await wait(ANIMATE_IN_OUT_DURATION * 1000);
    setShowPreloader(false);
  }, [setShowPreloader, setShowPreloaderVisibly, setShowInitialGate]);

  /* =============================================
  Use Effects
  ============================================= */

  //Re-route to explore if on home
  useEffect(() => {
    prevPaths.current.push(router.pathname);
    const hasBeenToExplore = prevPaths.current.includes(routes.Explore.path);
    const isHome = router.pathname === routes.Home.path;
    const mostRecentPathIsPress = prevPaths.current[prevPaths.current.length - 2] === routes.Press.path;

    //Go to explore path
    if (userIsValid && isHome && hasBeenToExplore) {
      router.push(routes.Explore.path);
    }

    //Show the "enter experience" if coming back from Press
    //But havent been through experience
    if (isHome && mostRecentPathIsPress && !hasBeenToExplore) {
      setMountPassgateContainer(true);
      setMountPassgateChildren(true);
      setTimeout(() => {
        setShowInitialGate(true);
      }, TRANSITION_TIME_MS);
    }
  }, [router, userIsValid, setMountPassgateContainer, setMountPassgateChildren, setShowInitialGate]);

  //On PING loading complete,
  //Show items/state accordingly
  useEffect(() => {
    if (!isLoadingPing && userIsValid && requiresPreload) {
      setShowPreloader(true);
    } else if (!isLoadingPing && userIsValid && goStraightToPage) {
      setShowChildren(true);
      setShowInitialGate(false);
      setMountPassgateContainer(false);
    } else if (!isLoadingPing) {
      setShowInitialGate(true);
      setCurrentPagegateStep(userIsValid ? pagegateKeys.ENTER : pagegateKeys.PASSWORD);
    }
  }, [
    isLoadingPing,
    userIsValid,
    setShowPreloader,
    requiresPreload,
    setCurrentPagegateStep,
    setShowInitialGate,
    goStraightToPage,
    setShowChildren,
    setMountPassgateContainer
  ]);

  //Set default anim state of children
  useEffect(() => {
    const init = async () => {
      childrenAnimateInInit();
      visiblyHideMainElements();

      await wait(50);
      setMountPassgateChildren(true);

      await wait(50);
      visiblyShowMainElements();
    };

    init();
  }, [childrenAnimateInInit, visiblyHideMainElements, visiblyShowMainElements, setMountPassgateChildren]);

  //Animate in children
  useEffect(() => {
    if (showChildren) {
      childrenAnimateIn();
    }
  }, [showChildren, childrenAnimateIn]);

  //Showing loader and loading
  useEffect(() => {
    if (showPreloader && !loadingComplete && requiresPreload && webGLReady) {
      //These setters below are for if they started on a non-preload page like /press
      //And went to /explore from there but did not preload yet
      setShowChildren(false);
      setMountPassgateContainer(true);

      //Dispatch scene to webgl
      dispatch(setWebGLScene(SCENE_TYPES.MAIN));
    }
  }, [
    showPreloader,
    loadingComplete,
    dispatch,
    requiresPreload,
    setShowChildren,
    setMountPassgateContainer,
    webGLReady
  ]);

  useEffect(() => {
    const page = Object.values(routes).filter((el) => el.path === router.pathname);

    if (page.length) {
      setCurrentPage(page[0].title);
    }
  }, [router.pathname]);

  useEffect(() => {
    if (soundRef?.current) {
      soundTlRef.current = gsap
        .timeline({
          paused: true
        })
        .fromTo(soundRef.current, { volume: 0 }, { volume: 0.15, duration: 0.667 });
    }
  }, []);

  useEffect(() => {
    let sound = soundRef?.current;
    const isMediaPages = router.pathname === routes.Press.path || router.pathname === routes.Reviews.path;
    if (sound) {
      sound.play();
      if (isSoundOn && activeSlide !== 0 && !isPersonaHoldPlaying && !isMediaPages) {
        soundTlRef.current.play();
      } else {
        soundTlRef.current.reverse();
      }
    }
  }, [isSoundOn, activeSlide, isPersonaHoldPlaying, soundRef, soundTlRef, router.pathname]);

  return (
    <div className={classnames(styles.PageGateWrapper, className)}>
      <Head
        title={currentPage}
        description={
          process.env.NEXT_PUBLIC_PAGEGATE_DISABLED === 'true'
            ? 'Learn more about the newest addition to the Sonos sound system'
            : 'Sign in to join us and learn about the latest product launch'
        }
      />
      <audio id="ambient_audio_track" autoPlay={isAutoPlay} muted={!isSoundOn} loop ref={soundRef}>
        <source src={oggSoundtrack} type="audio/ogg" />
        <source src={soundtrack} type="audio/mpeg" />
      </audio>
      <TopNav onMediaClick={handleOnMediaClick} handleOnSonosLogoClick={handleOnSonosLogoClick} />
      {[routes._404.path, routes.unsupported.path].includes(router.pathname) ? (
        <>{children}</>
      ) : (
        <>
          {mountPassgateContainer && (
            <div ref={passGateContainerRef}>
              {mountPassgateChildren && (
                <>
                  <PasswordGate
                    ref={passwordGateRef}
                    className={styles.passwordGate}
                    isActive={!isLoadingPing && showInitialGate}
                    currentStep={currentPagegateStep}
                    onPasswordSuccess={handlePasswordSuccess}
                    onEnterExperienceClick={handleEnterClick}
                    handleSkipToMediaKitClick={handleSkipToMediaKitClick}
                  />
                  {!isLoadingPing && showPreloader && (
                    <Preloader
                      onLoadComplete={handleOnLoadComplete}
                      percentLoaded={percentLoaded}
                      isActive={!isLoadingPing && showPreloader && showPreloaderVisibly}
                    />
                  )}
                  <LoadingSpinner isActive={isLoadingPing} className={styles.loadingPulse} />
                </>
              )}
            </div>
          )}
          <div ref={childrenContainerRef}>{!isLoadingPing && showChildren && children}</div>
        </>
      )}
      <WipeElement />
      {!isTouchDevice && <Cursor />}
    </div>
  );
}

PageGateWrapper.propTypes = checkProps({
  className: PropTypes.string
});

PageGateWrapper.defaultProps = {};

export default memo(PageGateWrapper);
