import { Vector3 } from 'three';
import { gsap } from 'gsap';
import BaseScene from './base-scene';
import { clearFlags } from '../controllers/interaction-controller';
import { COLOR_SETS } from '../utils/constants';

export default class LandingScene extends BaseScene {
  constructor(cameraRatio = 1, type) {
    super('LandingScene', cameraRatio, type);

    this.direction = new Vector3();
    this.targetDirection = new Vector3();
    this.elements = null;

    this.setCameraPosition({ x: 0, y: 0, z: 5 }, { x: 0, y: 0, z: 0 });
  }

  setup = (props) => {
    const { config, element } = props;
    this.config = config;

    if (this.elements) {
      this.elements.dispose();
      this.elements = null;
      if (this.mesh) {
        this.scene.remove(this.mesh);
        this.mesh = null;
      }
    }

    const colorSet = COLOR_SETS[config.colorSet];

    this.elements = new element({ config, colorSet });

    this.mesh = this.elements.mesh;
    this.scene.add(this.mesh);

    this.setColorScheme(colorSet, 0.0, 0.0);

    this.initialAnimation = true;
    this.animateOut(0.0, 0.0);
  };

  animateIn = (duration = 1.0, delay = 0.0) => {
    return new Promise((resolve) => {
      if (this.elements) {
        if (this.initialAnimation) {
          delay += 0.667;
          duration *= 2;
        }

        let currentValue = this.elements.getOpacity();
        let currentAmount = this.elements.getAmount();

        gsap.to(currentValue, {
          duration,
          value: 1.0,
          delay,
          onUpdate: () => {
            this.elements.updateOpacity(currentValue.value);
          },
          onComplete: () => {
            this.initialAnimation = false;
            resolve();
          }
        });

        gsap.to(currentAmount, {
          duration,
          value: 1.0,
          ease: 'power1.in',
          delay,
          onUpdate: () => {
            this.elements.updateAmount(currentAmount.value);
          }
        });
      } else {
        resolve();
      }
    });
  };

  animateOut = (duration = 0.6, delay = 0.0) => {
    return new Promise((resolve) => {
      if (this.elements) {
        let currentValue = this.elements.getOpacity();
        let currentAmount = this.elements.getAmount();

        gsap.to(currentValue, {
          duration,
          value: 0.0,
          delay,
          onUpdate: () => {
            this.elements.updateOpacity(currentValue.value);
          },
          onComplete: () => {
            resolve();
          }
        });

        gsap.to(currentAmount, {
          duration,
          value: 0.0,
          delay,
          onUpdate: () => {
            this.elements.updateAmount(currentValue.value);
          }
        });
      } else {
        resolve();
      }
    });
  };

  setAnimationIntensity = (intensity = 0.5, time = 1.0, delay = 0.0) => {};

  setConfig = (config) => {
    if (this.elements) {
      this.elements.setConfig(config);
    }
    this.config = config;
  };

  resize = (ratio, zoomRatio = 1) => {
    this.setCameraPosition({ x: 0, y: 0, z: 5 * zoomRatio }, { x: 0, y: 0, z: 0 });
    this.camera.aspect = ratio;
    this.camera.updateProjectionMatrix();
  };

  reset = () => {
    this.targetDirection.x = 0;
    this.targetDirection.y = 0;
    this.targetDirection.z = 0;

    this.direction.lerp(this.targetDirection, 1.0);
    this.mousePosition.lerp(this.outMousePosition, 1.0);
    this.currentCameraPosition.lerp(this.targetCameraPosition, 1.0);

    if (this.elements) {
      this.elements.update(0.0, this.mousePosition, this.direction);
    }
  };

  update = (delta, controller, interactive, debug) => {
    if (process.env.NEXT_PUBLIC_PAGEGATE_DISABLED === 'true') return false;

    if (controller) {
      const pointer = controller.getPointer(0);

      let pos = this.outMousePosition;
      let ease = 0.001;

      if (interactive && pointer.onScreen) {
        pos = this.getMouseWorldPosition(pointer.center.x, -pointer.center.y, 0.5);
        ease = 1;
      }

      this.mousePosition.lerp(pos, ease);

      if (interactive && pointer.onContainer && pointer.onScreen) {
        this.targetDirection.x += pointer.direction.x;
        this.targetDirection.y += -pointer.direction.y;
        this.targetDirection.z = Math.abs(this.targetDirection.x + this.targetDirection.y) * this.config.distortionZ;
      } else {
        pointer.direction.x = 0;
        pointer.direction.y = 0;
      }

      this.targetDirection.clampScalar(-1.0, 1.0);
      this.direction.lerp(this.targetDirection, 0.2);

      if (this.elements) {
        this.elements.update(delta, this.mousePosition, this.direction);
      }

      this.targetDirection.x *= this.config.distortionDumping;
      this.targetDirection.y *= this.config.distortionDumping;
      this.targetDirection.z *= this.config.distortionDumping;

      if (debug) {
        debug.updateMousePosition(pos);
      }

      controller.reset(this.config.distortionSmoothness, clearFlags.DIRECTION);
      return true;
    } else {
      return false;
    }
  };

  dispose = () => {
    if (this.elements) {
      this.elements.dispose();
    }
  };
}
