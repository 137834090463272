import { Vector3 } from 'three';

export function deleteAllProperties(obj) {
  for (const key in obj) {
    delete obj[key];
  }
}

export function updateCameraMatrices(camera) {
  camera.updateMatrix();
  camera.updateMatrixWorld();
  camera.matrixWorldInverse.copy(camera.matrixWorld).invert();
}

export function worldToScreen(position, camera, screenWidth, screenHeight, vector) {
  updateCameraMatrices(camera);

  const width = screenWidth || window.innerWidth;
  const height = screenHeight || window.innerHeight;
  const widthHalf = width / 2;
  const heightHalf = height / 2;

  vector = vector || new Vector3();

  vector.copy(position);
  vector.project(camera);

  return {
    x: (vector.x * widthHalf + widthHalf) | 0,
    y: (-(vector.y * heightHalf) + heightHalf) | 0
  };
}

export function clamp(value, min, max) {
  return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
}

export function randomRange(min, max) {
  return min + Math.random() * (max - min);
}

export function randomNumber() {
  return Math.floor(Math.random() * 1000000000);
}
