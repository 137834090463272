import { gsap } from 'gsap';
import detect from '../../../../utils/detect';

export const AudioDataType = {
  FREQUENCY: 0,
  TIMEDOMAIN: 1
};

export default class VisualizationController {
  constructor(datatype = AudioDataType.TIMEDOMAIN, fftSize = 128) {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    if (this.audioContext === undefined) {
      console.log('VisualizationController:: WebAudio API not Available');
      return;
    }

    this.timer = 0;
    this.count = 5;
    this.volume = 1.0;

    this.analyser = this.audioContext.createAnalyser();
    this.dataArray = null;
    this.playing = false;
    this.audioSet = false;
    this.dataType = datatype;
    this.source = null;
    this.arrayBuffer = null;
    this.stream = null;

    this.setFFTSize(fftSize);
  }

  setFFTSize = (fftSize = 128) => {
    this.fftSize = this.dataType === AudioDataType.FREQUENCY ? fftSize * 2 : fftSize;
    this.analyser.fftSize = fftSize;
    this.bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(this.bufferLength);
    this.fftSize = fftSize;
  };

  getAudioData = () => {
    if (this.source && this.dataArray) {
      switch (this.dataType) {
        case AudioDataType.FREQUENCY:
          this.analyser.getByteFrequencyData(this.dataArray);
          break;

        case AudioDataType.TIMEDOMAIN:
          this.analyser.getByteTimeDomainData(this.dataArray);
          break;

        default:
          this.analyser.getByteFrequencyData(this.dataArray);
          break;
      }

      if (detect.browser.isSafari) {
        if (this.timer >= this.count) {
          let baseval = Math.random() * 20;
          let size = this.dataArray.length > 32 ? 32 : this.dataArray.length;
          for (let i = 0; i < size; i++) {
            this.dataArray[i] =
              this.dataArray[i] === 0 ? (baseval + Math.random() * 20 * (i * 0.2)) * this.volume : this.dataArray[i];
          }
          this.timer = 0;
        }
        this.timer++;
      }

      return { data: this.dataArray, length: this.bufferLength };
    } else {
      return null;
    }
  };

  start = (audio, stream = null) => {
    if (stream) {
      this.stream = stream;
      this.source = this.audioContext.createMediaStreamSource(this.stream);
      this.source.connect(this.analyser);
      this.playing = true;
    } else {
      this.source = this.audioContext.createMediaElementSource(audio);
      this.source.connect(this.analyser);
      this.source.connect(this.audioContext.destination);
      this.playing = true;
    }
  };

  stop = () => {
    this.source = null;
    this.stream = null;
    this.playing = false;
  };

  setSound = (mute) => {
    gsap.to(this, {
      duration: 1,
      volume: mute ? 0.0 : 1.0,
      ease: 'sine.inout'
    });
  };

  dispose = () => {};
}
