import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wipeRef: null,
  wipeDirection: null,
  wipeIsAnimating: false
};

export const wipeSlice = createSlice({
  name: 'wipe',
  initialState: initialState,
  reducers: {
    setWipeRef(state, action) {
      return { ...state, wipeRef: action.payload };
    },
    setWipeDirection(state, action) {
      return { ...state, wipeDirection: action.payload };
    },
    setWipeIsAnimating(state, action) {
      return { ...state, wipeIsAnimating: action.payload };
    }
  }
});
