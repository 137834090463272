import { createSlice } from '@reduxjs/toolkit';
import headerKeys from '../../keys/header';

const initialState = {
  headerTheme: headerKeys.PEACH
};

export const headerSlice = createSlice({
  name: 'header',
  initialState: initialState,
  reducers: {
    setHeaderTheme(state, action) {
      return { ...state, headerTheme: action.payload };
    }
  }
});
