export default {
  P1: {
    id: 'P1',
    title: 'Grille',
    description:
      'The updated grille hints at improved acoustic performance, with precise perforation that matches the design of our premium soundbar, Arc.'
  },
  P2: {
    id: 'P2',
    title: 'NFC',
    description:
      'NFC simplifies first-time setup by securely passing WiFi credentials from a smartphone or other device to the soundbar.'
  },
  P3: {
    id: 'P3',
    title: 'Touch Controls',
    description:
      'Tap the capacitive controls to adjust the volume, play/pause music, and mute the microphones. Swipe left and right to replay or skip tracks.'
  },
  P4: {
    id: 'P4',
    title: 'Microphones',
    description:
      'The far-field microphone array uses advanced beamforming and multi-channel echo cancellation for quick and accurate voice control.'
  },
  P5: {
    id: 'P5',
    title: 'IR Receiver',
    description: "Beam's IR Receiver syncs with your existing TV remote for streamlined control and no extra clutter."
  },
  P6: {
    id: 'P6',
    title: 'Updated Finish',
    description:
      'Both colorways have been updated with a completely monochromatic finish, including a color-matched cable cove, power cable, and HDMI cable.'
  },
  P7: {
    id: 'P7',
    title: 'HDMI eARC',
    description:
      'New HDMI eARC connection enables support for even more surround sound formats including Dolby Atmos, Dolby TrueHD, Dolby Digital Plus, and uncompressed multichannel LPCM, with support for decoding DTS Digital Surround coming soon.'
  }
};
