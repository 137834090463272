import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSoundOn: false
};

export const soundSlice = createSlice({
  name: 'sound',
  initialState: initialState,
  reducers: {
    setIsSoundOn(state, action) {
      return { ...state, isSoundOn: action.payload };
    }
  }
});
