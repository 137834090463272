import React, { memo, useImperativeHandle, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { gsap } from 'gsap';

import styles from './Rings.module.scss';

import { ringsEase } from '../../data/eases';

const Rings = React.forwardRef(({ className, initialScale, duration }, ref) => {
  const ringsArr = new Array(3).fill(null);
  const ringRefs = useRef({});

  //Initialize default anim state of component
  const animateInInit = useCallback(() => {
    gsap.killTweensOf(Object.values(ringRefs.current));
    gsap.set(Object.values(ringRefs.current), {
      autoAlpha: 0,
      scale: initialScale ? initialScale : 0
    });
  }, [initialScale]);

  //Animation Out
  const animateOut = useCallback(() => {
    const tl = gsap.timeline();

    //Animate out rest of rings
    const ringDur = duration ? duration : 2;
    const ringsStag = 0.31;

    //Expand rings
    tl.to(
      Object.values(ringRefs.current),
      {
        stagger: ringsStag,
        duration: ringDur,
        scale: 1,
        ease: ringsEase
      },
      0
    );

    //Show rings
    tl.to(
      Object.values(ringRefs.current),
      {
        stagger: ringsStag,
        duration: 0.15,
        autoAlpha: 0.7,
        ease: 'linear'
      },
      0
    );

    tl.to(
      Object.values(ringRefs.current),
      {
        stagger: ringsStag,
        duration: ringDur / 2,
        autoAlpha: 0,
        ease: 'linear'
      },
      ringDur / 2
    );
  }, [duration]);

  useImperativeHandle(ref, () => ({
    animateInInit,
    animateOut
  }));

  useEffect(() => {
    animateInInit();
  }, [animateInInit]);

  return (
    <div className={classnames(styles.Rings, className)}>
      {ringsArr.map((item, i) => (
        <div key={i} ref={(ref) => (ringRefs.current[i] = ref)} className={styles.ring} />
      ))}
    </div>
  );
});

Rings.propTypes = checkProps({
  className: PropTypes.string,
  initialScale: PropTypes.number,
  duration: PropTypes.number
});

Rings.defaultProps = {};

export default memo(Rings);
