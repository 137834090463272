import whiteBeam from '../assets/images/sonos-white-beam.png';
import personaKeys from '../keys/personas';

//Persona videos
import gamerVideoMP4 from '../assets/videos/persona_gamer.mp4';
import gamerVideoWEBM from '../assets/videos/persona_gamer.webm';
import sportsVideoMP4 from '../assets/videos/persona_sports.mp4';
import sportsVideoWEBM from '../assets/videos/persona_sports.webm';
import musicVideoMP4 from '../assets/videos/persona_music.mp4';
import musicVideoWEBM from '../assets/videos/persona_music.webm';
import moviesVideoMP4 from '../assets/videos/persona_movies.mp4';
import moviesVideoWEBM from '../assets/videos/persona_movies.webm';

import bingeWatcher1 from '../assets/images/personas/personas-binge-watcher-1.jpg';
import bingeWatcher2 from '../assets/images/personas/personas-binge-watcher-2.jpg';
import bingeWatcher3 from '../assets/images/personas/personas-binge-watcher-3.jpg';
import bingeWatcher4 from '../assets/images/personas/personas-binge-watcher-4.jpg';
import bingeWatcher5 from '../assets/images/personas/personas-binge-watcher-5.jpg';
import bingeWatcher6 from '../assets/images/personas/personas-binge-watcher-6.jpg';

import musicLover1 from '../assets/images/personas/personas-music-lover-1.jpg';
import musicLover2 from '../assets/images/personas/personas-music-lover-2.jpg';
import musicLover3 from '../assets/images/personas/personas-music-lover-3.jpg';
import musicLover4 from '../assets/images/personas/personas-music-lover-4.jpg';
import musicLover5 from '../assets/images/personas/personas-music-lover-5.jpg';
import musicLover6 from '../assets/images/personas/personas-music-lover-6.jpg';

import gamer1 from '../assets/images/personas/personas-gamer-1.jpg';
import gamer2 from '../assets/images/personas/personas-gamer-2.jpg';
import gamer3 from '../assets/images/personas/personas-gamer-3.jpg';
import gamer4 from '../assets/images/personas/personas-gamer-4.jpg';
import gamer5 from '../assets/images/personas/personas-gamer-5.jpg';
import gamer6 from '../assets/images/personas/personas-gamer-6.jpg';

import sportsFan1 from '../assets/images/personas/personas-sports-fan-1.jpg';
import sportsFan2 from '../assets/images/personas/personas-sports-fan-2.jpg';
import sportsFan3 from '../assets/images/personas/personas-sports-fan-3.jpg';
import sportsFan4 from '../assets/images/personas/personas-sports-fan-4.jpg';
import sportsFan5 from '../assets/images/personas/personas-sports-fan-5.jpg';
import sportsFan6 from '../assets/images/personas/personas-sports-fan-6.jpg';

const personaTitles = {
  [personaKeys.MOVIE_BUFF]: 'Binge Watcher',
  [personaKeys.GAMER]: 'Gamer',
  [personaKeys.AUDIOPHILE]: 'Music Lover',
  [personaKeys.SPORTS]: 'Sports Fan',
  [personaKeys.EXPLORE]: 'Explore Beam'
};

export default {
  backLabel: 'Click To View More',
  change: 'Change Your Persona',
  holdPrompt: 'Press + Hold',
  releasePrompt: 'Release',
  [personaKeys.MOVIE_BUFF]: {
    video: {
      src: {
        mp4: moviesVideoMP4,
        webm: moviesVideoWEBM
      }
    },
    beamImage: {
      src: whiteBeam,
      alt: 'Beam Image'
    },
    items: [
      {
        title: 'The immersive experience of Dolby Atmos',
        splitTitleText: true,
        description:
          'With the 3D audio positioning of Dolby Atmos and precise localization from Beam, you’ll get a virtual surround sound experience worthy of the best Hollywood blockbusters.'
      },
      {
        title: 'Dialogue that truly pops',
        description:
          'Oscar-winning sound mixers and engineers helped fine-tune Beam for exceptional dialogue clarity. You can also turn on Speech Enhancement in the Sonos app to amplify voices in the mix so you don’t lose track of the story or miss a whisper.'
      },
      {
        title: 'Tried, tested, and truly powerful',
        description:
          'Sonos engineers put Beam through 1000 hours of testing at max volume in extreme temperatures to make sure it’s ready for your movie marathons and long weekends spent bingeing multiple seasons of your new favorite show.'
      },
      {
        title: 'Enrich your entertainment',
        cta: 'Explore the Product'
      }
    ],
    navigationLabels: ['Dolby Atmos', 'Improved Dialogue Clarity', 'Bar Raising Performance', 'Learn More']
  },
  [personaKeys.GAMER]: {
    video: {
      src: {
        mp4: gamerVideoMP4,
        webm: gamerVideoWEBM
      }
    },
    beamImage: {
      src: whiteBeam,
      alt: 'Beam Image'
    },
    items: [
      {
        title: 'The best way to get in the game',
        description:
          'With a new HDMI eARC connection, Beam can receive and decode even more surround sound formats from your game console, including Dolby Atmos, Dolby TrueHD, Dolby Digital Plus, and uncompressed multichannel LPCM, with support for DTS Digital Surround coming soon.'
      },
      {
        title: 'Compact and packed with power',
        description:
          'Thanks to the new technology, including a 40% faster chip, Beam produces bigger-than-ever sound in the same elegant, low-profile design which means more space by the TV for your next generation console.'
      },
      {
        title: 'A sound system that lives up to your internet speed',
        description:
          'Drop-outs and lag can ruin a game. Beam uses a dedicated 5GHz WiFi radio to connect with optional surround speakers and Sub for a reliable, low-latency connection.'
      },
      {
        title: 'Enrich your entertainment',
        cta: 'Explore the Product'
      }
    ],
    navigationLabels: ['Adaptive and Immersive', 'Compact Power', 'Speed of Sound', 'Learn More']
  },
  [personaKeys.SPORTS]: {
    video: {
      src: {
        mp4: sportsVideoMP4,
        webm: sportsVideoWEBM
      }
    },
    beamImage: {
      src: whiteBeam,
      alt: 'Beam Image'
    },
    items: [
      {
        title: 'All about teamwork',
        description:
          'Never miss a play when you create a multiroom Sonos system. Easily group Beam with other Sonos speakers around your home, so you can still enjoy the game while you prep food in the kitchen and grill on the patio.'
      },
      {
        title: 'Unbeatable sound',
        description:
          'The extra wide soundstage balances the roars of the crowd and impacts on the field with exceptional dialogue clarity for commentary.'
      },
      {
        title: 'Complete coverage of the game',
        description: 'Watch the game while streaming your favorite local radio commentary broadcast from Sonos Radio.'
      },
      {
        title: 'Enrich your entertainment',
        cta: 'Explore the Product'
      }
    ],
    navigationLabels: ['Multiroom Magic', 'Balanced Performance', 'Unmatched Flexibility', 'Learn More']
  },
  [personaKeys.AUDIOPHILE]: {
    video: {
      src: {
        mp4: musicVideoMP4,
        webm: musicVideoWEBM
      }
    },
    beamImage: {
      src: whiteBeam,
      alt: 'Beam Image'
    },
    items: [
      {
        title: 'A soundbar and stereo in one',
        description:
          'On top of a virtual surround sound experience for TV and movies, Beam delivers impressive stereo sound for music plus radio, podcasts, and audiobooks. Stream from all your favorite services (Sonos works with over 100) and experience crisp highs, vibrant lows, and impressive depth.'
      },
      {
        title: 'Easy control with the app',
        description:
          'The Sonos app walks you through a few simple steps to set up Beam and brings all your controls and audio content together in one place. It also gives you access to Sonos Radio, where you can discover new music, explore original content, and stream thousands of live stations from around the world.* \n\n *Sonos Radio availability and experience varies by region.'
      },
      {
        title: 'More immersive music',
        description:
          'With plans to support Ultra HD and Dolby Atmos Music in select markets later this year, Sonos will deliver an upgraded, more immersive sound experience from Amazon Music. Ultra HD—the highest quality streaming audio available—lets you hear music just as artists intended, and Dolby Atmos Music makes listening to a growing catalog of tracks on Amazon Music a 3D experience.'
      },
      {
        title: 'Enrich your entertainment',
        cta: 'Explore the Product'
      }
    ],
    navigationLabels: ['Virtual Surround Sound', 'Easy App Control', 'Dolby Atmos Music', 'Learn More']
  },
  selectPersona: {
    title: 'Select Your Persona',
    clickImage: 'Click Image to enter',
    ...personaTitles
  },
  personaTransition: {
    [personaKeys.MOVIE_BUFF]: {
      title: personaTitles[personaKeys.MOVIE_BUFF],
      images: [bingeWatcher1, bingeWatcher2, bingeWatcher3, bingeWatcher4, bingeWatcher5, bingeWatcher6]
    },
    [personaKeys.AUDIOPHILE]: {
      title: personaTitles[personaKeys.AUDIOPHILE],
      images: [musicLover1, musicLover2, musicLover3, musicLover4, musicLover5, musicLover6]
    },
    [personaKeys.GAMER]: {
      title: personaTitles[personaKeys.GAMER],
      images: [gamer1, gamer2, gamer3, gamer4, gamer5, gamer6]
    },
    [personaKeys.SPORTS]: {
      title: personaTitles[personaKeys.SPORTS],
      images: [sportsFan1, sportsFan2, sportsFan3, sportsFan4, sportsFan5, sportsFan6]
    },
    [personaKeys.EXPLORE]: {
      title: personaTitles[personaKeys.EXPLORE],
      images: []
    }
  }
};
