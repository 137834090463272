import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { appSlice } from './modules/slice-app';
import { wipeSlice } from './modules/slice-wipe';
import { webGLSlice } from './modules/slice-webgl';
import { soundSlice } from './modules/slice-sound';
import { videoSlice } from './modules/slice-video';
import { slideSlice } from './modules/slice-slide';
import { cursorSlice } from './modules/slice-cursor';
import { userSlice } from './modules/slice-user';
import { headerSlice } from './modules/slice-header';

const reducer = combineReducers({
  app: appSlice.reducer,
  wipe: wipeSlice.reducer,
  webGL: webGLSlice.reducer,
  video: videoSlice.reducer,
  slide: slideSlice.reducer,
  cursor: cursorSlice.reducer,
  user: userSlice.reducer,
  sound: soundSlice.reducer,
  header: headerSlice.reducer
});

export const { setPrevRoute, setIsWebpSupported, setSelectedColor, setActivePersona } = appSlice.actions;

export const { setWipeRef, setWipeDirection, setWipeIsAnimating } = wipeSlice.actions;

export const {
  setPercentLoaded,
  setLoadingComplete,
  setWebGLScene,
  setProduct,
  setShowHotspot,
  setHotspot,
  resetHotspot,
  setWebGLReady
} = webGLSlice.actions;

export const { setIsPlaying, setPlaybackProgress, setVideoLength, setIsPersonaHoldPlaying } = videoSlice.actions;

export const { setActiveSlide, setNavHovered, setNavIsActive } = slideSlice.actions;

export const { setIsSoundOn } = soundSlice.actions;

export const { setCursorState, setIsMouseDown, setIsHoldComplete, setHoverAreaId } = cursorSlice.actions;

export const { setUserIsValid } = userSlice.actions;

export const { setHeaderTheme } = headerSlice.actions;

export const store = configureStore({
  reducer: reducer
});
