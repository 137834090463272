import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import gsap from 'gsap';

import HoverArea from '../HoverArea/HoverArea';

import alignmentKeys from '../../keys/alignment';
import themeKeys from '../../keys/theme';
import { cursorKeys } from '../../keys/cursor';

import routes from '../../data/routes';

import styles from './SonosLogo.module.scss';

import { setActiveSlide, setWebGLScene, setProduct } from '../../redux';
import { SCENE_TYPES } from '../WebGL/WebGLApp/utils/constants';
import { PRODUCT_TYPES } from '../../components/WebGL/WebGLApp/utils/constants';
import { TRANSITION_TIME } from '../../data/constants';

//SVGs
import SvgSonosLogo from '../../assets/svgs/svg-sonos-logo.svg';

function SonosLogo({ className, alignment, theme, onClick }) {
  const dispatch = useDispatch();
  const logoRef = useRef(null);
  const { loadingComplete, webGLReady } = useSelector((state) => state.webGL);
  const { userIsValid } = useSelector((state) => state.user);

  //intro animation

  useEffect(() => {
    gsap.to(logoRef.current, {
      scale: 1,
      opacity: 1,
      duration: 0.667,
      delay: TRANSITION_TIME * 2
    });
  }, [userIsValid]);

  return (
    <HoverArea cursorType={cursorKeys.FOCUS}>
      <header className={classnames(styles.SonosLogo, className, styles[alignment], styles[theme])}>
        <Link href={loadingComplete ? routes.Explore.path : routes.Home.path} className={styles.button}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={styles.button}
            ref={logoRef}
            onClick={() => {
              if (!userIsValid) return;

              if (onClick) {
                onClick();
              }

              if (webGLReady) {
                if (loadingComplete) {
                  dispatch(setActiveSlide(1));
                  dispatch(setWebGLScene(SCENE_TYPES.PRODUCT));
                  dispatch(setProduct(PRODUCT_TYPES.EMPTY));
                } else {
                  dispatch(setWebGLScene(SCENE_TYPES.MAIN));
                }
              }
            }}
          >
            <SvgSonosLogo aria-label="Sonos Logo" />
            <span className={styles.screenReaderOnly}>Sonos logo</span>
          </a>
        </Link>
      </header>
    </HoverArea>
  );
}

SonosLogo.propTypes = checkProps({
  className: PropTypes.string,
  alignment: PropTypes.oneOf([alignmentKeys.LEFT, alignmentKeys.CENTER]),
  theme: PropTypes.oneOf([themeKeys.LIGHT, themeKeys.DARK]),
  onClick: PropTypes.func
});

SonosLogo.defaultProps = {
  alignment: alignmentKeys.LEFT,
  theme: themeKeys.LIGHT
};

export default memo(SonosLogo);
