import { ShaderMaterial, Vector4, Vector3, Color } from 'three';
import wavesVert from '../../../glsl/waves.vert';
import wavesFrag from '../../../glsl/waves.frag';

const WaveMaterial = (opts = {}) => {
  let color = new Color(opts.color) || new Color(0x000000);
  color = new Vector4(color.r, color.g, color.b, 1.0);

  let lightColor = new Color(opts.lightColor) || new Color(0x000000);
  lightColor = new Vector4(lightColor.r, lightColor.g, lightColor.b, 1.0);

  let offset = opts.offset || new Vector4(0.0, 0.0, 0.0, 0.0);

  const uniforms = {
    uOffset: { value: offset },
    uColor: { value: color },
    uLightColor: { value: lightColor },
    uLightIntensity: { value: 0.3 },
    uLightRadius: { value: 3.0 },
    uLightSpread: { value: 0.0 },
    uTime: { value: 0.0 },
    uMouse: { value: new Vector4(0.0, 0.0, 0.0, 0.0) },
    uDirection: { value: new Vector3(0.0, 0.0, 0.0) },
    //x: intensity X, y: intensity Y, z: wave speed in, w: wave speed out
    uDistortionIntensity: { value: new Vector4(2.0, 2.0, 2.0, 3.0) },
    //x: speed, y:tile , z:offset y, w: intensity
    uNoiseIntensity: { value: new Vector4(0.4, 2.0, 0.8, 0.5) },
    //x: radius, y:spread, z:power, w:wave y variation
    uDistortionAmount: { value: new Vector4(2.0, 0.0, 1.2, 2.3) },
    uOpacity: { value: 0.0 },
    uAmount: { value: 0.0 }
  };

  return new ShaderMaterial({
    uniforms: uniforms,
    transparent: true,
    vertexShader: wavesVert,
    fragmentShader: wavesFrag
  });
};

export default WaveMaterial;
